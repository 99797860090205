<table *ngIf="stockStylePercentages && stockStyleAmounts" class="stock-style-nine-box">
    <tr>
        <th></th>
        <th scope="col">Value</th>
        <th scope="col">Blend</th>
        <th scope="col">Growth</th>
    </tr>
    <tr>
        <th scope="row">Large</th>
        <td>
            {{stockStyleAmounts.largeValue | currency}}<br />
            {{stockStylePercentages.largeValue | percent:'1.2-2'}}
            <p *ngIf="hasBenchmarks" class="benchmark">
                vs. VTSAX<br />
                {{benchmarkStockStylePercentages.largeValue | percent:'1.2-2'}}
            </p>
        </td>
        <td>
            {{stockStyleAmounts.largeBlend | currency}}<br />
            {{stockStylePercentages.largeBlend | percent:'1.2-2'}}
            <p *ngIf="hasBenchmarks" class="benchmark">
                vs. VTSAX<br />
                {{benchmarkStockStylePercentages.largeBlend | percent:'1.2-2'}}
            </p>
        </td>
        <td>
            {{stockStyleAmounts.largeGrowth | currency}}<br />
            {{stockStylePercentages.largeGrowth | percent:'1.2-2'}}
            <p *ngIf="hasBenchmarks" class="benchmark">
                vs. VTSAX<br />
                {{benchmarkStockStylePercentages.largeGrowth | percent:'1.2-2'}}
            </p>
        </td>
    </tr>
    <tr>
        <th scope="row">Medium</th>
        <td>
            {{stockStyleAmounts.mediumValue | currency}}<br />
            {{stockStylePercentages.mediumValue | percent:'1.2-2'}}
            <p *ngIf="hasBenchmarks" class="benchmark">
                vs. VTSAX<br />
                {{benchmarkStockStylePercentages.mediumValue | percent:'1.2-2'}}
            </p>
        </td>
        <td>
            {{stockStyleAmounts.mediumBlend | currency}}<br />
            {{stockStylePercentages.mediumBlend | percent:'1.2-2'}}
            <p *ngIf="hasBenchmarks" class="benchmark">
                vs. VTSAX<br />
                {{benchmarkStockStylePercentages.mediumBlend | percent:'1.2-2'}}
            </p>
        </td>
        <td>
            {{stockStyleAmounts.mediumGrowth | currency}}<br />
            {{stockStylePercentages.mediumGrowth | percent:'1.2-2'}}
            <p *ngIf="hasBenchmarks" class="benchmark">
                vs. VTSAX<br />
                {{benchmarkStockStylePercentages.mediumGrowth | percent:'1.2-2'}}
            </p>
        </td>
    </tr>
    <tr>
        <th scope="row">Small</th>
        <td>
            {{stockStyleAmounts.smallValue | currency}}<br />
            {{stockStylePercentages.smallValue | percent:'1.2-2'}}
            <p *ngIf="hasBenchmarks" class="benchmark">
                vs. VTSAX<br />
                {{benchmarkStockStylePercentages.smallValue | percent:'1.2-2'}}
            </p>
        </td>
        <td>
            {{stockStyleAmounts.smallBlend | currency}}<br />
            {{stockStylePercentages.smallBlend | percent:'1.2-2'}}
            <p *ngIf="hasBenchmarks" class="benchmark">
                vs. VTSAX<br />
                {{benchmarkStockStylePercentages.smallBlend | percent:'1.2-2'}}
            </p>
        </td>
        <td>
            {{stockStyleAmounts.smallGrowth | currency}}<br />
            {{stockStylePercentages.smallGrowth | percent:'1.2-2'}}
            <p *ngIf="hasBenchmarks" class="benchmark">
                vs. VTSAX<br />
                {{benchmarkStockStylePercentages.smallGrowth | percent:'1.2-2'}}
            </p>
        </td>
    </tr>
</table>
