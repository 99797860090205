import { Injectable } from '@angular/core';
import { HttpClientWrapperService } from './http-client-wrapper.service';
import * as models from '../swagger-codegen/models';

@Injectable()
export class AuthenticationProxy {
    private baseUrl = '/api/authentication';

    constructor(private httpClient: HttpClientWrapperService) {
    }

    login(model: models.AuthUserTokenObtainPair) {
        return this.httpClient.post<models.TokenPair>(`${this.baseUrl}/`, model);
    }

    loginWithToken(model: models.TokenRefresh) {
        return this.httpClient.post<models.TokenPair>(`${this.baseUrl}/refresh`, model);
    }

    logout() {
        return this.httpClient.delete(`${this.baseUrl}/`);
    }
}
