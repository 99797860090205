import { Injectable } from '@angular/core';
import { HttpClientWrapperService } from './http-client-wrapper.service';
import * as models from '../swagger-codegen/models';

@Injectable()
export class AccountsProxy {
    private baseUrl = '/api/accounts';

    constructor(private httpClient: HttpClientWrapperService) {
    }

    getAccount(accountId: number) {
        return this.httpClient.get<models.Account>(`${this.baseUrl}/${accountId}`);
    }

    getAccountHoldings(accountId: number) {
        return this.httpClient.get<[models.AccountHolding]>(`${this.baseUrl}/${accountId}/holdings`);
    }

    getAccountHoldingTransactions(accountId: number, since?: Date, types?: string[]) {
        let query = '';

        if(since || types) {
            query = '?';

            if(since) {
                query += `since=${since.toISOString().split('T')[0]}&`;
            }

            if(types && types.length) {
                query += `types=${types.join(',')}&`;
            }
        }

        return this.httpClient.get<[models.AccountHoldingTransaction]>(`${this.baseUrl}/${accountId}/transactions${query}`);
    }

    getAccountPurposes() {
        return this.httpClient.get<[models.AccountPurpose]>(`${this.baseUrl}/purposes`);
    }

    getAccountHoldingBalanceHistory(accountId: number, securityId: number) {
        return this.httpClient.get<[models.AccountHoldingBalanceHistory]>(
            `${this.baseUrl}/${accountId}/holdings/${securityId}/balances`);
    }

    saveTargetAllocations(targetAllocations: models.AccountHoldingTargetAllocation[]) {
        return this.httpClient.put<any>(`${this.baseUrl}/holdings/target-allocations`, targetAllocations);
    }

    reconcileAccountHoldings(accountHoldings: models.AccountHoldingReconciliation[]) {
        return this.httpClient.put<any>(`${this.baseUrl}/holdings/reconciliation`, accountHoldings);
    }
}
