import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { AuthGuard } from '../shared/auth/auth-guard.service';
import { AppMainComponent } from '../app.main.component';
import { SecurityCompareComponent } from './compare/security-compare.component';
import { SecurityDetailComponent } from './detail/security-detail.component';
import { SecurityListComponent } from './list/security-list.component';
import { SecuritySearchComponent } from './search/security-search.component';

export const routes: Routes = [
    {
        path: 'securities',
        component: AppMainComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: SecurityListComponent,
                data: {
                    title: 'Securities'
                }
            },
            {
                path: 'new',
                component: SecurityDetailComponent,
                data: {
                    title: 'New Security'
                }
            },
            {
                path: 'compare',
                component: SecurityCompareComponent,
                data: {
                    title: 'Security Compare'
                }
            },
            {
                path: ':securityId',
                component: SecurityDetailComponent,
                data: {
                    title: 'Security Detail'
                }
            },
            {
                path: 'search',
                component: SecuritySearchComponent,
                data: {
                    title: 'Security Search'
                }
            }
        ]
    }
];

export const securitiesRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
