import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { accountsRoutes } from './accounts.routes';
import { SharedModule } from '../shared/shared.module';
import { AccountListComponent } from './list/account-list.component';
import { AccountReturnsTableComponent } from './list/account-returns-table.component';
import { AllocationComponent } from './analytics/allocation/allocation.component';
import { AccountAnalyticsComponent } from './analytics/account-analytics.component';
import { VanguardRecommendedAllocationComponent } from './analytics/vanguard-recommended-allocation.component';
import { AccountRebalanceComponent } from './analytics/account-rebalance/account-rebalance.component';
import { RebalanceAllocationTableComponent } from './analytics/account-rebalance/rebalance-allocation-table.component';
import { RebalanceStockExposureComponent } from './analytics/account-rebalance/rebalance-stock-exposure.component';
import { RebalanceStockStylesComponent } from './analytics/account-rebalance/rebalance-stock-styles.component';
import { AssetAllocationComponent } from './analytics/asset-allocation/asset-allocation.component';
import { HoldingAllocationComponent } from './analytics/holding-allocation/holding-allocation.component';
import { IndexAllocationComponent } from './analytics/index-allocation/index-allocation.component';
import { InvestmentTypeAllocationComponent } from './analytics/investment-type-allocation/investment-type-allocation.component';
import { MarketCapAllocationComponent } from './analytics/market-cap-allocation/market-cap-allocation.component';
import { RegionAllocationComponent } from './analytics/region-allocation/region-allocation.component';
import { SectorAllocationComponent } from './analytics/sector-allocation/sector-allocation.component';
import { StockExposureComponent } from './analytics/stock-exposure/stock-exposure.component';
import { StockStylesComponent } from './analytics/stock-styles/stock-styles.component';
import { StockStylesNineBoxComponent } from './analytics/stock-styles/stock-styles-nine-box.component';
import { BalanceHistoryDialogComponent } from './balance-history-dialog/balance-history-dialog.component';
import { BalanceHistoryDialogService } from './balance-history-dialog/balance-history-dialog.service';
import { TransactionImportComponent } from './transactions/import/transaction-import.component';
import { TransactionListComponent } from './transactions/list/transaction-list.component';
import { WhatIfComponent } from './transactions/what-if/what-if.component';
import { WhatIfService } from './transactions/what-if/what-if.service';

@NgModule({
    imports: [
        accountsRoutes,
        CommonModule,
        FormsModule,
        SharedModule
    ],
    declarations: [
        AccountListComponent,
        AccountReturnsTableComponent,
        AllocationComponent,
        AccountAnalyticsComponent,
        VanguardRecommendedAllocationComponent,
        AccountRebalanceComponent,
        RebalanceAllocationTableComponent,
        RebalanceStockExposureComponent,
        RebalanceStockStylesComponent,
        AssetAllocationComponent,
        BalanceHistoryDialogComponent,
        HoldingAllocationComponent,
        IndexAllocationComponent,
        InvestmentTypeAllocationComponent,
        MarketCapAllocationComponent,
        RegionAllocationComponent,
        SectorAllocationComponent,
        StockExposureComponent,
        StockStylesComponent,
        StockStylesNineBoxComponent,
        TransactionImportComponent,
        TransactionListComponent,
        WhatIfComponent
    ],
    providers: [
        BalanceHistoryDialogService,
        WhatIfService
    ]
})
export class AccountsModule {

}
