import { Component } from '@angular/core';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

@Component({
    selector: 'my-vanguard-recommended-allocation',
    template: `
        <h6>Vanguard Recommendations:</h6>
        <ol>
            <li>Choose your allocation of Stocks and Bonds based on your risk profile.</li>
            <li>
                Your stock allocation should be:
                <ul>
                    <li>60-70% US Stocks</li>
                    <li>30-40% Non-US Stocks (Broad market including emerging markets. Not specific regions.)</li>
                </ul>
            <li>
                Your bond allocation should be:
                <ul>
                    <li>70-80% US Bonds</li>
                    <li>20-30% Non-US Bonds (Broad market excluding emerging markets. Not specific regions.)</li>
                </ul>
            </li>
        </ol>
    `
})
export class VanguardRecommendedAllocationComponent extends BaseComponentDirective {
    constructor() {
        super();
    }
}
