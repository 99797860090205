<div class="grid form-group">
    <div class="field col-12 lg:col-3">
        <span class="p-float-label">
            <p-dropdown name="accountId" [(ngModel)]="accountId" [options]="accountItems" styleClass="width-full" [autoDisplayFirst]="false" (onChange)="onAccountChanged()"></p-dropdown>
            <label>Account</label>
        </span>
    </div>
    <div class="field col-12 lg:col-3" *ngIf="accountId">
        <span class="p-float-label">
            <p-dropdown name="holdingId" [(ngModel)]="securityId" [options]="holdingItems" styleClass="width-full" (onChange)="onHoldingChanged()"></p-dropdown>
            <label>Holding</label>
        </span>
    </div>
    <div class="field col-12 lg:col-2" *ngIf="accountId">
        <span class="p-float-label">
            <p-dropdown name="securityType" [(ngModel)]="securityType" [options]="securityTypes" styleClass="width-full" (onChange)="onSecurityTypeChanged()"></p-dropdown>
            <label>Security Type</label>
        </span>
    </div>
    <div class="field col-12 lg:col-1" *ngIf="accountId">
        <span class="p-float-label">
            <p-dropdown name="transactionType" [(ngModel)]="transactionType" [options]="transactionTypes" styleClass="width-full" (onChange)="onTransactionTypeChanged()"></p-dropdown>
            <label>Transaction Type</label>
        </span>
    </div>
    <div class="col-12 lg:col-2" *ngIf="canCompare">
        <a [routerLink]="'/accounts/transactions/what-if'">Compare Transactions</a>
    </div>
    <div class="col-12">
        <p-table [value]="filteredTransactions" [loading]="isBusy" [paginator]="showPager" [pageLinks]="10" [rows]="pageSize" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md">
            <ng-template pTemplate="header">
                <tr>
                    <th class="width2">Date</th>
                    <th class="left">Holding</th>
                    <th class="width3">Type</th>
                    <th class="left">Description</th>
                    <th class="width3 right">Shares</th>
                    <th class="width2 right">Price</th>
                    <th class="width2 right">Fees</th>
                    <th class="width3 right">Total</th>
                    <th class="width1">
                        <i class="fas fa-plus clickable add-icon" pTooltip="Add All Transactions to Compare" (click)="addAllToCompare()"></i>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-transaction let-rowIndex="rowIndex">
                <tr>
                    <td>
                        <span class="p-column-title">Date</span>
                        {{transaction.date | date:'shortDate'}}
                    </td>
                    <td class="left">
                        <span class="p-column-title">Holding</span>
                        {{transaction.accountHolding.security.displayText}}
                    </td>
                    <td>
                        <span class="p-column-title">Type</span>
                        {{transaction.type}}
                    </td>
                    <td class="left">
                        <span class="p-column-title">Description</span>
                        {{transaction.description}}
                    </td>
                    <td class="right">
                        <span class="p-column-title">Shares</span>
                        {{transaction.shares | number:'1.4-4'}}
                    </td>
                    <td class="right">
                        <span class="p-column-title">Price</span>
                        {{transaction.price | currency}}
                    </td>
                    <td class="right">
                        <span class="p-column-title">Fees</span>
                        {{transaction.fees | currency}}
                    </td>
                    <td class="right">
                        <span class="p-column-title">Total</span>
                        {{transaction.total | currency}}
                    </td>
                    <td>
                        <i *ngIf="showAddToCompareIcon(transaction)" class="fas fa-plus clickable add-icon" pTooltip="Add to Compare" (click)="addToCompare(transaction)"></i>
                        <i *ngIf="showRemoveFromCompareIcon(transaction)" class="fas fa-minus clickable remove-icon" pTooltip="Remove from Compare" (click)="removeFromCompare(transaction)"></i>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="columnCount">
                        {{isBusy ? 'Loading transactions...' : 'No transactions.'}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
