import { Injectable } from '@angular/core';
import { SessionService } from '../auth/session.service';

import { User, Account } from '../../shared/swagger-codegen/models';

@Injectable()
export class CurrentUserService implements  User {
    constructor(private session: SessionService) {
    }

    accounts: Account[] = [];

    get isAuthenticated() {
        return this.session.getUser() !== undefined;
    }

    get isAdmin() {
        return this.session.getUser().isAdmin;
    }

    get userId() {
        return this.getUserOrDefault().userId || 0;
    }

    get email() {
        return this.getUserOrDefault().email;
    }

    get firstName() {
        return this.getUserOrDefault().firstName;
    }

    get lastName() {
        return this.getUserOrDefault().lastName;
    }

    private getUserOrDefault() {
        return this.session.getUser() || {} as User;
    }
}
