<div class="grid form-group">
    <div class="col-12">
        <p-dropdown name="importType" [(ngModel)]="importType" [options]="importTypes" styleClass="width10" placeholder="Select an Import Type" required (onChange)="onImportTypeChanged()"></p-dropdown>
    </div>
    <div *ngIf="importTypeIsSelected" class="col-12">
        <p-dropdown name="importAccount" [(ngModel)]="accountId" [options]="filteredAccounts" styleClass="width10" placeholder="Select the Account to Import To" required (onChange)="onAccountChanged()"></p-dropdown>
    </div>
    <div *ngIf="isTIAAImport" class="col-12">
        To create a TIAA statement file for this import:
        <ol>
            <li>Download a <a href="https://shared.tiaa.org/private/partaccounts/accounts/statements" target="_tiaa">TIAA Statement</a> PDF.</li>
            <li>Open the pdf then go to the transaction section.</li>
            <li>
                Go page by page, selecting the transaction text then copy and paste it into a text file. Each transaction will
                paste as 3 lines like:
                <p>
                1/11/2019 1/11/2019 Contribution<br />
                BlackRock MC Eq Idx M<br />
                1.2374 $19.7504 $24.44
                </p>
            </li>
            <li>Save the file once all transactions have been pasted into it.</li>
        </ol>
    </div>
    <div *ngIf="isVanguardImport" class="col-12">
        To create a Vanguard transaction csv file for this import:
        <ol>
            <li>Go to Vanguard.com's <a href="https://holdings.web.vanguard.com/" target="_vanguard">Balances &amp; Holdings</a> page and click the Download Center link at the top right of the page.</li>
            <li>Hit F12 to open Developer Tools and go to the Elements tab</li>
            <li>In the Styles tab add a filter for hidePageIfJSdisabled</li>
            <li>In the hidePageIfJSdisabled class uncheck the 'display: none' line</li>
            <li>For Step 1 choose 'A spreadsheet-compatible CSV file'.</li>
            <li>For Step 2 choose your date range (whole months are best).</li>
            <li>For Step 3 choose your account (do one at a time).</li>
            <li>Click the Download button then save the file.</li>
        </ol>
    </div>
    <div *ngIf="isFidelityImport" class="col-12">
        To create a Fidelity transaction csv file for this import:
        <ol>
            <li>Go to <a href="https://401k.com">401k.com</a> and click the Account.</li>
            <li>Click the Transaction History sub-tab.</li>
            <li>Click the Transaction History link.</li>
            <li>Click the Download Transaction History link.</li>
            <li>Select the Time Period.</li>
            <li>Select CSV as teh File Format.</li>
            <li>Click the Download button then save the file.</li>
        </ol>
    </div>
    <div *ngIf="showStatementFileUpload" class="col-12">
        <p-fileUpload #fileUpload [chooseLabel]="fileUploadLabel" [name]="fileUploadName" [url]="getFileUploadUrl()" accept=".csv,.txt" [auto]="true" mode="basic" (onBeforeUpload)="onBeforeFileUploaded()" (onUpload)="onFileUploaded()" (onError)="onFileUploadError()"></p-fileUpload>
    </div>
    <div *ngIf="showHoldings" class="col-12">
        <div class="with-bottom-margin">
            <p-checkbox [(ngModel)]="showZeroBalanceHoldings" (onChange)="showZeroBalanceHoldingsChanged()" label="Show Zero Balance Holdings" [binary]="true"></p-checkbox>
        </div>
        <p-table [value]="filteredHoldings" [loading]="isBusy">
            <ng-template pTemplate="header">
                <tr>
                    <th class="width2 center">Reconcile</th>
                    <th class="left">Holding</th>
                    <th class="width3">First<br />Transaction</th>
                    <th class="width3">Last<br />Transaction</th>
                    <th class="width3">Reconciled<br />Thru</th>
                    <th class="width5 right">Reconciled<br />Shares</th>
                    <th class="width5 right">Shares</th>
                    <th class="width5 right">Cost<br />Basis</th>
                    <th class="width5 right">Balance</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-holding let-rowIndex="rowIndex">
                <tr [ngClass]="getRowCssClass(holding)">
                    <td class="center">
                        <span class="p-column-title">Reconcile</span>
                        <p-checkbox [(ngModel)]="holding.reconcile" [binary]="true"></p-checkbox>
                    </td>
                    <td class="left">
                        <span class="p-column-title">Holding</span>
                        {{holding.security.name}}
                        <span *ngIf="holding.security.ticker">({{holding.security.ticker}})</span>
                    </td>
                    <td class="center">
                        <span class="p-column-title">First Transaction</span>
                        {{holding.firstTransactionDate | date:'shortDate'}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Last Transaction</span>
                        {{holding.lastTransactionDate | date:'shortDate'}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">Reconciled Thru</span>
                        {{holding.reconciledThruDate | date:'shortDate'}}
                    </td>
                    <td class="right" [ngClass]="getReconciledSharesCssClass(holding)">
                        <span class="p-column-title">Reconciled Shares</span>
                        {{holding.reconciledShares | number:'1.4-4'}}
                    </td>
                    <td class="right">
                        <span class="p-column-title">Shares</span>
                        {{holding.shares | number:'1.4-4'}}
                    </td>
                    <td class="right">
                        <span class="p-column-title">Cost Basis</span>
                        {{holding.costBasis | currency}}
                    </td>
                    <td class="right">
                        <span class="p-column-title">Balance</span>
                        {{holding.balance | currency}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div *ngIf="hasHoldingsToReconcile()" class="col-12">
        <form #reconcileForm="ngForm" autocomplete="off" novalidate="novalidate">
            <div class="grid p-formgrid">
                <div class="field col-12">
                    <label for="reconcileThru">Reconcile Date:</label>
                    <p-calendar inputId="reconcileThru" name="reconcileThru" [(ngModel)]="reconciledThruDate" [defaultDate]="reconciledThruDate" [maxDate]="today" required></p-calendar>
                </div>
                <div class="field col-12">
                    <button type="submit" pButton label="Reconcile" (click)="reconcile()" [disabled]="isBusy || !reconcileForm.form.valid"></button>
                </div>
            </div>
        </form>
    </div>
</div>
