import { Injectable } from '@angular/core';
import { HttpClientWrapperService } from './http-client-wrapper.service';
import * as models from '../swagger-codegen/models';

@Injectable()
export class SecuritiesProxy {
    private baseUrl = '/api/securities';

    constructor(private httpClient: HttpClientWrapperService) {
    }

    getSecurities() {
        return this.httpClient.get<[models.Security]>(this.baseUrl);
    }

    getSecurity(securityId: number) {
        return this.httpClient.get<models.Security>(`${this.baseUrl}/${securityId}`);
    }

    deleteSecurity(securityId: number) {
        return this.httpClient.delete<any>(`${this.baseUrl}/${securityId}`);
    }

    saveSecurity(security: models.Security) {
        if(security.securityId > 0) {
            return this.httpClient.put<models.Security>(`${this.baseUrl}/${security.securityId}`, security);
        }
        else {
            return this.httpClient.post<models.Security>(`${this.baseUrl}/`, security);
        }
    }

    searchSecurities(search: string) {
        return this.httpClient.get<models.Security[]>(`${this.baseUrl}/search?search=${search}`);
    }

    getPriceHistory(securityId: number, since: Date) {
        const date = since.toISOString().split('T')[0];
        return this.httpClient.get<models.SecurityPriceHistory[]>(`${this.baseUrl}/${securityId}/price-history?since=${date}`);
    }

    getHoldings(securityId: number) {
        return this.httpClient.get<models.SecurityHolding[]>(`${this.baseUrl}/${securityId}/holdings`);
    }

    getSecurityTypes() {
        return this.httpClient.get<models.SecurityType[]>(`${this.baseUrl}/types`);
    }
}
