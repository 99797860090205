<div class="grid">
    <div class="col-12 lg:col-5" style="max-height: 800px; overflow: auto;">
        <p-checkbox [(ngModel)]="showZeroBalanceHoldings" (onChange)="showZeroBalanceHoldingsChanged()" label="Show Zero Balance, Zero Target Holdings" [binary]="true"></p-checkbox>
        <div *ngFor="let account of accountsWithAllocations">
            <p-table [value]="account.holdings" styleClass="p-datatable-striped">
                <ng-template pTemplate="caption">
                    {{account.name}} {{account.balance | currency}}
                </ng-template>
                <ng-template pTemplate="header">
                    <tr valign="bottom">
                        <th class="left">Name</th>
                        <th class="width2 right">
                            <span class="clickable" (click)="setAllRebalanceAllocationsToCurrent(account)" pTooltip="Set All to Current Allocation">
                                Current
                            </span>
                        </th>
                        <th class="width2">
                            <span class="clickable" (click)="setAllRebalanceAllocationsToTarget(account)" pTooltip="Set All to Target Allocation">
                                Target
                            </span>
                        </th>
                        <th class="width3 right">Rebalance</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-holding let-rowIndex="rowIndex">
                    <tr>
                        <td class="left">
                            <span class="p-column-title">Name</span>
                            <i class="fas fa-minus clickable remove-icon" pTooltip="Remove Holding" (click)="removeHolding(account, rowIndex)"></i>
                            {{holding.name}}
                        </td>
                        <td class="right" valign="top">
                            <span class="p-column-title">Current</span>
                            {{holding.currentBalance | currency}}<br />
                            <span class="clickable" (click)="setRebalanceAllocation(account, holding, holding.currentAllocation)" pTooltip="Set to Current Allocation">
                                {{holding.currentAllocation | percent:'1.2-2'}}
                            </span><br />
                            <span [ngClass]="getAllocationDifferenceCssClass(holding.currentAllocation - holding.targetAllocation)">
                                {{holding.currentAllocation - holding.targetAllocation | percent:'1.2-2'}}
                            </span>
                        </td>
                        <td class="right" valign="top">
                            <span class="p-column-title">Target</span>
                            {{holding.targetBalance | currency}}<br />
                            <span class="clickable" (click)="setRebalanceAllocation(account, holding, holding.targetAllocation)" pTooltip="Set to Target Allocation">
                                {{holding.targetAllocation | percent:'1.2-2'}}
                            </span>
                        </td>
                        <td class="right" valign="top">
                            <span class="p-column-title">Rebalance</span>
                            {{holding.rebalanceBalance | currency}}<br />
                            <input type="number" name="rebalanceAllocation" pInputText class="width1 right" [(ngModel)]="holding.rebalanceAllocation" (change)="onRebalanceAllocationChanged(account, holding)">
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr>
                        <td>
                            Add Holding:
                            <p-dropdown [options]="securities" (onChange)="addHolding(account, $event.value)" optionLabel="displayText"  placeholder=" " styleClass="width10"></p-dropdown>
                        </td>
                        <td class="right">
                            {{account.currentAllocationTotal | percent:'1.2-2'}}
                        </td>
                        <td class="right">
                            {{account.targetAllocationTotal | percent:'1.2-2'}}
                        </td>
                        <td class="right" [ngClass]="getTotalRebalanceAllocationCssClass(account)">
                            {{account.rebalanceAllocationTotal | percent:'1.2-2'}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <p style="margin-top: 20px;">
            <button pButton type="button" label="Save Target Allocations" (click)="saveTargetAllocations()"></button>
        </p>
    </div>
    <div class="col-12 lg:col-7">
        <p-tabView>
            <p-tabPanel header="Asset Allocation">
                <my-vanguard-recommended-allocation></my-vanguard-recommended-allocation>
                <my-rebalance-allocation-table [allocations]="assetAllocations"></my-rebalance-allocation-table>
            </p-tabPanel>
            <p-tabPanel header="Market Caps">
                <ng-template pTemplate="content">
                    <my-rebalance-allocation-table [allocations]="marketCapAllocations"></my-rebalance-allocation-table>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Stock Styles">
                <ng-template pTemplate="content">
                    <my-rebalance-stock-styles [accountsWithAllocations]="accountsWithAllocations" [benchmarks]="benchmarks"></my-rebalance-stock-styles>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Regions">
                <ng-template pTemplate="content">
                    <my-rebalance-allocation-table [allocations]="regionAllocations"></my-rebalance-allocation-table>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Sectors">
                <ng-template pTemplate="content">
                    <my-rebalance-allocation-table [allocations]="sectorAllocations"></my-rebalance-allocation-table>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Holdings">
                <ng-template pTemplate="content">
                    <my-rebalance-allocation-table [allocations]="holdingAllocations" [showBenchmark]="false"></my-rebalance-allocation-table>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Stock Exposure">
                <ng-template pTemplate="content">
                    <my-rebalance-stock-exposure [accountsWithAllocations]="accountsWithAllocations" [securityHoldings]="securityHoldings"></my-rebalance-stock-exposure>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Instructions" *ngIf="rebalancedAccounts.length > 0">
                <ng-template pTemplate="content">
                    <div *ngFor="let rebalancedAccount of rebalancedAccounts">
                        <p-table [value]="rebalancedAccount.rebalanceInstructions" styleClass="p-datatable-striped">
                            <ng-template pTemplate="caption">
                                {{rebalancedAccount.name}}
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr valign="bottom">
                                    <th class="left">Name</th>
                                    <th class="width2 center">Action</th>
                                    <th class="width2 right">Amount</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-instruction let-rowIndex="rowIndex">
                                <tr>
                                    <td class="left">
                                        <span class="p-column-title">Name</span>
                                        {{instruction.name}}
                                    </td>
                                    <td class="center">
                                        <span class="p-column-title">Action</span>
                                        {{instruction.action}}<br *ngIf="instruction.percent" />
                                        {{instruction.percent | percent:'1.2-2'}}
                                    </td>
                                    <td class="right">
                                        <span class="p-column-title">Amount</span>
                                        {{instruction.amount | currency}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </ng-template>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
