<div class="grid">
    <div class="col-12 lg:col-6" style="text-align: center">
        <h4>Current</h4>
        <my-stock-styles-nine-box
            [stockStylePercentages]="currentStockStylePercentages"
            [stockStyleAmounts]="currentStockStyleAmounts"
            [benchmarkStockStylePercentages]="benchmarkStockStylePercentages"></my-stock-styles-nine-box>
    </div>
    <div class="col-12 lg:col-6" style="text-align: center">
        <h4>Rebalance</h4>
        <my-stock-styles-nine-box
            [stockStylePercentages]="rebalanceStockStylePercentages"
            [stockStyleAmounts]="rebalanceStockStyleAmounts"
            [benchmarkStockStylePercentages]="benchmarkStockStylePercentages"></my-stock-styles-nine-box>
    </div>
</div>
