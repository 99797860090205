/* eslint-disable */
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

import { appRoutes } from './app-routing.module';
import { AppCodeModule } from './app.code.component';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppConfigComponent } from './app.config.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppInlineMenuComponent } from './app.inlinemenu.component';
import { AppRightMenuComponent } from './app.rightmenu.component';
import { AppBreadcrumbComponent } from './app.breadcrumb.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';

import { MenuService } from './app.menu.service';
import { AppBreadcrumbService } from './app.breadcrumb.service';
import { AccountsModule } from './accounts/accounts.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { SecuritiesModule } from './securities/securities.module';
import { SharedModule } from './shared/shared.module';
import { UsersModule } from './users/users.module';

@NgModule({ declarations: [
        AppComponent,
        AppMainComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppInlineMenuComponent,
        AppRightMenuComponent,
        AppBreadcrumbComponent,
        AppTopBarComponent,
        AppFooterComponent,
    ],
    bootstrap: [
        AppComponent
    ], imports: [appRoutes,
        AppCodeModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        AccountsModule,
        AuthenticationModule,
        SecuritiesModule,
        SharedModule.forRoot(),
        UsersModule], providers: [
        AppBreadcrumbService,
        MenuService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {

}
