import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import * as models from '../../../shared/swagger-codegen/models';
import { AccountWithHoldingAllocations, AccountHoldingWithAllocations } from './account-rebalance.component';

interface StockExposure {
    name: string;
    payoffProfile: string;
    currentAmount: number;
    currentPercent: number;
    rebalancedAmount: number;
    rebalancedPercent: number;
    cusip: string;
    isin: string;
    ticker: string;
    security?: models.Security;
}

interface SecurityHoldingWithAmount extends models.SecurityHolding {
    currentAmount: number;
    rebalancedAmount: number;
}

@Component({
    selector: 'my-rebalance-stock-exposure',
    templateUrl: './rebalance-stock-exposure.component.html'
})
export class RebalanceStockExposureComponent extends BaseComponentDirective implements OnInit, OnChanges {
    constructor() {
        super();
    }

    @Input()
    accountsWithAllocations: AccountWithHoldingAllocations[] = [];
    @Input()
    securityHoldings: { [securityId: number]: models.SecurityHolding[] } = {};
    filteredStockExposures: StockExposure[] = [];
    filter = '';
    isBusy = false;
    private stockExposures: StockExposure[] = [];

    get isFiltered() {
        return this.filter && this.filter.trim().length > 0;
    }

    ngOnInit() {
    }

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ngOnChanges(changes: SimpleChanges) {
        this.loadStockExposure();
    }

    applyFilter() {
        if(this.isFiltered) {
            const filter = this.filter.trim().toLowerCase();

            this.filteredStockExposures = this.stockExposures
                .filter(s => s.name.toLowerCase().indexOf(filter) >= 0
                    || (s.ticker && s.ticker.toLowerCase().indexOf(filter) >= 0)
                    || (s.cusip && s.cusip.toLowerCase().indexOf(filter) >= 0)
                    || (s.isin && s.isin.toLowerCase().indexOf(filter) >= 0)
                );
        }
        else {
            this.filteredStockExposures = this.stockExposures;
        }
    }

    clearFilter() {
        this.filter = '';
        this.applyFilter();
    }

    getExposurePercentFormat(percent: number) {
        let decimals = 2;
        let value = 0.0001;
        let format = '1.2-2';

        for(let i = 0; i < 15; i++) {
            if(percent > value) {
                format = `1.${decimals}-${decimals}`;
                break;
            }

            decimals++;
            value /= 10;
        }

        return format;
    }

    getExposureCurrencyFormat(amount: number) {
        let decimals = 2;
        let value = 0.01;
        let format = '1.2-2';

        for(let i = 0; i < 15; i++) {
            if(amount >= value) {
                format = `1.${decimals}-${decimals}`;
                break;
            }

            decimals++;
            value /= 10;
        }

        return format;
    }

    private loadStockExposure() {
        try {
            this.isBusy = true;

            let total = 0;
            const securityHoldingsWithAmounts: { [key: string]: SecurityHoldingWithAmount } = {};
            const stockExposures: StockExposure[] = [];

            this.accountsWithAllocations.forEach(account => {
                total += account.balance;
                this.addSecurityHoldingTotals(account.holdings, securityHoldingsWithAmounts);
            });

            const stocks = this.getIndividualStockTotals();

            Object.keys(securityHoldingsWithAmounts)
                .forEach(key => {
                    const securityHolding = securityHoldingsWithAmounts[key];
                    const stockExposure: StockExposure = {
                        name: securityHolding.name,
                        payoffProfile: securityHolding.payoffProfile,
                        currentAmount: securityHolding.currentAmount,
                        currentPercent: 0,
                        rebalancedAmount: securityHolding.rebalancedAmount,
                        rebalancedPercent: 0,
                        cusip: securityHolding.cusip,
                        isin: securityHolding.isin,
                        ticker: securityHolding.ticker
                    };

                    if(stockExposure.payoffProfile === 'Long') {
                        const i = stocks.findIndex(s => {
                            return s.security.cusip === securityHolding.cusip
                                || s.security.isin === securityHolding.isin
                                || s.security.ticker === securityHolding.ticker;
                        });

                        if(i >= 0) {
                            const stock = stocks[i];
                            stockExposure.currentAmount += stock.currentAmount;
                            stockExposure.rebalancedAmount += stock.rebalancedAmount;
                            stocks.splice(i, 1);
                        }
                    }

                    stockExposure.currentPercent = stockExposure.currentAmount / total;
                    stockExposure.rebalancedPercent = stockExposure.rebalancedAmount / total;
                    stockExposures.push(stockExposure);
                });

            stocks.forEach(stock => {
                stockExposures.push({
                    name: stock.name,
                    payoffProfile: 'Long',
                    currentAmount: stock.currentAmount,
                    currentPercent: stock.currentAmount / total,
                    rebalancedAmount: stock.rebalancedAmount,
                    rebalancedPercent: stock.rebalancedAmount / total,
                    cusip: stock.security.cusip,
                    isin: stock.security.isin,
                    ticker: stock.security.ticker
                });
            });

            this.stockExposures = stockExposures
                .filter(exposure => exposure.currentAmount > 0 || exposure.rebalancedAmount > 0)
                .sort((a, b) => a.rebalancedAmount < b.rebalancedAmount ? 1 : -1);
        }
        finally {
            this.isBusy = false;
            this.applyFilter();
        }
    }

    private addSecurityHoldingTotals(
        accountHoldings: AccountHoldingWithAllocations[],
        securityHoldingsWithAmounts: { [key: string]: SecurityHoldingWithAmount }) {

        accountHoldings.forEach(holding => {
                const securityHoldings = this.securityHoldings[holding.security.securityId];

                if(securityHoldings) {
                    securityHoldings.forEach(securityHolding => {
                        const key = [
                            securityHolding.legalEntityIdentifier || 'N/A',
                            securityHolding.name || 'N/A',
                            securityHolding.payoffProfile || 'N/A'
                        ].join('|');

                        let holdingAmounts = securityHoldingsWithAmounts[key];

                        if(!holdingAmounts) {
                            holdingAmounts = {
                                securityHoldingId: 0,
                                legalEntityIdentifier: securityHolding.legalEntityIdentifier,
                                name: securityHolding.name,
                                payoffProfile: securityHolding.payoffProfile,
                                currentAmount: 0,
                                rebalancedAmount: 0,
                                percent: 0,
                                cusip: securityHolding.cusip,
                                isin: securityHolding.isin,
                                ticker: securityHolding.ticker
                            };

                            securityHoldingsWithAmounts[key] = holdingAmounts;
                        }

                        holdingAmounts.currentAmount += holding.currentBalance * securityHolding.percent;
                        holdingAmounts.rebalancedAmount += holding.rebalanceBalance * securityHolding.percent;
                    });
                }
            });
    }

    private getIndividualStockTotals() {
        const individualStock = 3;
        const totals: { [securityId: number]: StockExposure } = {};

        this.accountsWithAllocations.forEach(account => {
            account.holdings
                .filter(holding => holding.security.securityType.securityTypeId === individualStock)
                .forEach(holding => {
                    let total = totals[holding.security.securityId];

                    if(!total) {
                        total = {
                            name: holding.security.name,
                            payoffProfile: 'Long',
                            currentAmount: 0,
                            currentPercent: 0,
                            rebalancedAmount: 0,
                            rebalancedPercent: 0,
                            cusip: holding.security.cusip,
                            isin: holding.security.isin,
                            ticker: holding.security.ticker,
                            security: holding.security
                        };

                        totals[holding.security.securityId] = total;
                    }

                    total.currentAmount += holding.currentBalance;
                    total.rebalancedAmount += holding.rebalanceBalance;
                });
        });

        return Object.keys(totals)
            .map(key => totals[key] as StockExposure);
    }
}
