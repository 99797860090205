<div class="grid">
    <div class="field col-12" *ngIf="isNewSecurity">
        <span class="p-float-label">
            <my-security-search class="p-inputwrapper-focus width-full" (securitySelected)="onSecuritySelected($event)"></my-security-search>
            <label>Search</label>
        </span>
    </div>

    <p-tabView orientation="left">
        <p-tabPanel header="Details">
            <form *ngIf="security" #securityForm="ngForm" autocomplete="off" novalidate="novalidate">
                <div class="col-12 lg:col-6">
                    <div class="grid form-group">
                        <div class="field col-12">
                            <span class="p-float-label">
                                <input #name="ngModel" name="name" class="width-full" pInputText [(ngModel)]="security.name"
                                       maxlength="100" required>
                                <label>
                                    Name
                                    <span class="error" *ngIf="name.dirty && name.hasError('required')">
                                        Required<i class="fa ui-icon-error"></i>
                                    </span>
                                </label>
                            </span>
                        </div>
                        <div class="field col-12">
                            <span class="p-float-label">
                                <input name="ticker" class="width4" pInputText [(ngModel)]="security.ticker" maxlength="10">
                                <label>Ticker</label>
                            </span>
                        </div>
                        <div class="field col-12" *ngIf="showCusip">
                            <span class="p-float-label">
                                <input name="cusip" class="width4" pInputText [(ngModel)]="security.cusip" maxlength="9">
                                <label>CUSIP</label>
                            </span>
                        </div>
                        <div class="field col-12">
                            <span class="p-float-label">
                                <p-dropdown #securityType="ngModel" name="securityType" styleClass="width10" [autoDisplayFirst]="false" [(ngModel)]="security.securityType.securityTypeId"
                                            [options]="securityTypes" required></p-dropdown>
                                <label>
                                    Type
                                    <span class="error" *ngIf="securityType.dirty && securityType.hasError('required')">
                                        Required<i class="fa ui-icon-error"></i>
                                    </span>
                                </label>
                            </span>
                        </div>
                        <div class="field col-12" *ngIf="showMorningstarSecurityId">
                            <span class="p-float-label">
                                <input name="morningstarSecurityId" class="width6" pInputText [(ngModel)]="security.morningstarSecurityId" maxlength="15">
                                <label>Morningstar Security Id</label>
                            </span>
                        </div>
                        <div class="field col-12" *ngIf="showMorningstarPerformanceId">
                            <span class="p-float-label">
                                <input name="morningstarPerformanceId" class="width6" pInputText [(ngModel)]="security.morningstarPerformanceId" maxlength="10">
                                <label>Morningstar Performance Id</label>
                            </span>
                        </div>
                        <div class="field col-12" *ngIf="showAssetClass">
                            <span class="p-float-label">
                                <p-dropdown #assetClass="ngModel" name="assetClass" styleClass="width10" [autoDisplayFirst]="false" [(ngModel)]="security.assetClass"
                                            [options]="assetClasses" required></p-dropdown>
                                <label>
                                    Asset Class
                                    <span class="error" *ngIf="assetClass.dirty && assetClass.hasError('required')">
                                        Required<i class="fa ui-icon-error"></i>
                                    </span>
                                </label>
                            </span>
                        </div>
                        <div class="field col-12" *ngIf="showEdgarClassId">
                            <span class="p-float-label">
                                <input #edgarClassId="ngModel"  name="edgarClassId" class="width8" pInputText [(ngModel)]="security.edgarClassId" maxlength="10">
                                <label>
                                    Edgar Class Id (e.g. C000105304)
                                    <span class="error" *ngIf="edgarClassId.dirty && edgarClassId.hasError('required')">
                                        Required<i class="fa ui-icon-error"></i>
                                    </span>
                                </label>
                            </span>
                            <p>
                                <a [href]="edgarFundSearchUrl" target="_blank">Edgar Fund Search</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <button type="submit" pButton label="{{getSaveButtonText()}}" (click)="save()" [disabled]="isBusy || !securityForm.valid"></button>
                </div>
            </form>
        </p-tabPanel>
        <p-tabPanel header="Attributes">
            <ng-template pTemplate="content">
                <div class="grid">
                    <div class="col-12">
                        <my-security-attributes [securities]="[security]"></my-security-attributes>
                    </div>
                </div>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
</div>
