import { Injectable } from '@angular/core';
import { HttpClientWrapperService } from './http-client-wrapper.service';
import * as models from '../swagger-codegen/models';

@Injectable()
export class InstitutionsProxy {
    private baseUrl = '/api/institutions';

    constructor(private httpClient: HttpClientWrapperService) {
    }

    getInstitutions() {
        return this.httpClient.get<[models.Institution]>(this.baseUrl);
    }

    getInstitution(institutionId: number) {
        return this.httpClient.get<models.Institution>(`${this.baseUrl}/${institutionId}`);
    }
}
