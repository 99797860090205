<div *ngIf="currentUser" [ngClass]="{'layout-inline-menu': true, 'layout-inline-menu-active':appMain.inlineMenuActive[key]}" [ngStyle]="style" [class]="styleClass">
    <a class="layout-inline-menu-action flex flex-row align-items-center" [ngClass]="appMain.isHorizontal() ? 'p-3 py-3' : 'p-3'" (click)="onClick($event)"
        [pTooltip]="currentUser.firstName + ' ' + currentUser.lastName" [tooltipDisabled]="isTooltipDisabled">
 		<i class="fas fa-user-circle clickable profile-icon"></i>
        <span class="flex flex-column" [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">
            <span class="font-bold">{{currentUser.firstName}} {{currentUser.lastName}}</span>
            <small>{{currentUser.email}}</small>
        </span>
        <i class="layout-inline-menu-icon pi pi-angle-down" [ngClass]="{'ml-auto': !app.isRTL, 'mr-auto': app.isRTL}"></i>
    </a>

    <ul class="layout-inline-menu-action-panel" [@menu]="appMain.inlineMenuActive[key] ? isHorizontalActive() ? 'visible' : 'visibleAnimated' : isHorizontalActive() ? 'hidden' : 'hiddenAnimated'">
        <!--
        <li class="layout-inline-menu-action-item" pTooltip="Settings" [tooltipDisabled]="isTooltipDisabled">
            <a class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
                <i class="pi pi-cog pi-fw"></i>
                <span>Settings</span>
            </a>
        </li>
        <li class="layout-inline-menu-action-item" pTooltip="Terms of Usage" [tooltipDisabled]="isTooltipDisabled">
            <a class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
                <i class="pi pi-file-o pi-fw"></i>
                <span>Terms of Usage</span>
            </a>
        </li>
		-->
        <li class="layout-inline-menu-action-item" pTooltip="Import Transactions" [tooltipDisabled]="isTooltipDisabled">
            <a class="flex flex-row align-items-center" [attr.tabindex]="tabIndex" [routerLink]="['/accounts/transactions/import']">
                <i class="pi pi-cog"></i>
                <span>Import Transactions</span>
            </a>
        </li>
        <li class="layout-inline-menu-action-item" pTooltip="Logout" [tooltipDisabled]="isTooltipDisabled">
            <a class="flex flex-row align-items-center" [attr.tabindex]="tabIndex" [routerLink]="['/logout']">
                <i class="pi pi-power-off pi-fw"></i>
                <span>Logout</span>
            </a>
        </li>
    </ul>
</div>
