import { Injectable } from '@angular/core';
import { HttpHandler, HttpEvent, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class DefaultHeadersInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const timezoneOffset = new Date().getTimezoneOffset() * -1;
        const headers = req.headers
            .set('X-Timezone-Offset', timezoneOffset.toString());

        const reqClone = req.clone(
            {
                headers: headers
            });

        return next.handle(reqClone);
    }
}
