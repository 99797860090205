import { Injectable } from '@angular/core';
import { HttpClientWrapperService } from './http-client-wrapper.service';
import * as models from '../swagger-codegen/models';

@Injectable()
export class UsersProxy {
    private baseUrl = '/api/users';

    constructor(private httpClient: HttpClientWrapperService) {
    }

    getUsers() {
        return this.httpClient.get<[models.User]>(this.baseUrl);
    }

    getUser(userId: number) {
        return this.httpClient.get<models.User>(`${this.baseUrl}/${userId}`);
    }

    getCurrentUser() {
        return this.httpClient.get<models.User>(`${this.baseUrl}/current`);
    }

    getUserAccounts(userId: number) {
        return this.httpClient.get<[models.Account]>(`${this.baseUrl}/${userId}/accounts`);
    }

    getUserAccountsAndHoldings(userId: number, includeClosed?: boolean) {
        const query = includeClosed === undefined ? '' : `?includeClosed=${includeClosed}`;
        return this.httpClient.get<[models.AccountAndHoldings]>(`${this.baseUrl}/${userId}/accounts/holdings${query}`);
    }
}
