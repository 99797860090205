import { Injectable } from '@angular/core';
import { StorageService } from '../core/storage.service';

import * as models from '../swagger-codegen/models';

@Injectable()
export class SessionService {
    private accessTokenKey = 'token';
    private refreshTokenKey = 'refreshToken';
    private userKey = 'user';

    constructor(private storageService: StorageService) {

    }

    getToken(): string {
        return this.storageService.getSessionItem(this.accessTokenKey);
    }

    getRefreshToken(): string {
        return this.storageService.getItem(this.refreshTokenKey);
    }

    getUser(): models.User {
        const json = this.storageService.getSessionItem(this.userKey);
        return json ? JSON.parse(json) : null;
    }

    setUser(user: models.User) {
        const json = JSON.stringify(user);
        this.storageService.setSessionItem(this.userKey, json);
    }

    setTokens(tokens: models.TokenPair, rememberMe: boolean) {
        if(rememberMe && tokens.refresh) {
            this.storageService.setItem(this.refreshTokenKey, tokens.refresh);
        }
        else if(!rememberMe) {
            this.storageService.deleteItem(this.refreshTokenKey);
        }

        this.storageService.setSessionItem(this.accessTokenKey, tokens.access);
    }

    disposeSession() {
        this.storageService.deleteSessionItem(this.accessTokenKey);
        this.storageService.deleteItem(this.refreshTokenKey);

        this.storageService.deleteSessionItem(this.userKey);
        this.storageService.deleteItem(this.userKey);
    }
}
