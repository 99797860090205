import { Injectable } from '@angular/core';

/* eslint-disable no-var */
declare var window: any;

/* eslint-disable id-blacklist */
export interface BuildConfiguration {
    number: string;
    branch: string;
    version: string;
}

export interface Configuration {
    isDebug: boolean;
    environmentName: string;
    version: string;
    runtimeVersion: string;
    lastUpdated: Date;
    build: BuildConfiguration;
}

@Injectable()
export class ConfigurationService implements Configuration {
    constructor() {
        if(window.clarityConfig) {
            const config: Configuration = window.clarityConfig;
            this.isDebug = config.isDebug;
            this.environmentName = config.environmentName;
            this.version = config.version;
            this.runtimeVersion = config.runtimeVersion;
            this.lastUpdated = config.lastUpdated ? new Date(config.lastUpdated) : undefined;
            this.build = config.build;
        }
    }

    isDebug: boolean;
    environmentName: string;
    version: string;
    runtimeVersion: string;
    lastUpdated: Date;
    build: BuildConfiguration;
}
