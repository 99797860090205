import { Injectable } from '@angular/core';
import { HttpHandler, HttpEvent, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError as observableThrowError, Observable, from as observableFrom } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(
                catchError(err => this.handleError(req, err))
            );
    }

    private handleError(req: HttpRequest<any>, err: HttpErrorResponse): Observable<any> {
        if((err.status === 401 || err.status === 403) && req.url.toLowerCase().indexOf('/api/authentication') === -1) {
            this.router.navigateByUrl(`/login`);
            return observableFrom(err.message);
        }

        return observableThrowError(err);
    }
}
