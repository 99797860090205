<div class="with-bottom-margin">
    <p>
        <p-toggleButton class="with-right-margin" *ngFor="let account of allAccounts" [(ngModel)]="account.selected" [onLabel]="getAccountLabel(account)" [offLabel]="getAccountLabel(account)" onIcon="pi pi-check" offIcon="pi pi-times" (onChange)="onAccountFilterChanged()"></p-toggleButton>
    </p>
    <p>
        <p-toggleButton class="with-right-margin" *ngFor="let purpose of accountPurposes" [(ngModel)]="purpose.selected" [onLabel]="purpose.label" [offLabel]="purpose.label" onIcon="pi pi-check" offIcon="pi pi-times" (onChange)="onAccountPurposeChanged()"></p-toggleButton>
    </p>
</div>
<p-tabView orientation="left">
    <p-tabPanel header="Asset Allocation">
        <my-asset-allocation [accounts]="filteredAccounts" [benchmarks]="benchmarks"></my-asset-allocation>
    </p-tabPanel>
    <p-tabPanel header="Market Caps">
        <ng-template pTemplate="content">
            <my-market-cap-allocation [accounts]="filteredAccounts" [benchmarks]="benchmarks"></my-market-cap-allocation>
        </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Stock Styles">
        <ng-template pTemplate="content">
            <my-stock-styles [accounts]="filteredAccounts" [benchmarks]="benchmarks"></my-stock-styles>
        </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Regions">
        <ng-template pTemplate="content">
            <my-region-allocation [accounts]="filteredAccounts" [benchmarks]="benchmarks"></my-region-allocation>
        </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Sectors">
        <ng-template pTemplate="content">
            <my-sector-allocation [accounts]="filteredAccounts" [benchmarks]="benchmarks"></my-sector-allocation>
        </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Holdings">
        <ng-template pTemplate="content">
            <my-holding-allocation [accounts]="filteredAccounts"></my-holding-allocation>
        </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Indexes">
        <ng-template pTemplate="content">
            <my-index-allocation [accounts]="filteredAccounts"></my-index-allocation>
        </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Investment Types">
        <ng-template pTemplate="content">
            <my-investment-type-allocation [accounts]="filteredAccounts"></my-investment-type-allocation>
        </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Stock Exposure">
        <ng-template pTemplate="content">
            <my-stock-exposure [accounts]="filteredAccounts" [securityHoldings]="securityHoldings"></my-stock-exposure>
        </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Rebalancing">
        <ng-template pTemplate="content">
            <my-account-rebalance [accounts]="filteredAccounts" [securityHoldings]="securityHoldings"></my-account-rebalance>
        </ng-template>
    </p-tabPanel>
</p-tabView>
