import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { ServerProxiesModule } from './server-proxies/server-proxies.module';
import { UIModule } from './ui/ui.module';
import { SharedUsersModule } from './users/shared-users.module';

import { httpInterceptorProviders } from './http-interceptors/index';

@NgModule(
    {
        imports: [
        ],
        declarations: [
        ],
        exports: [
            AuthModule,
            CommonModule,
            CoreModule,
            FormsModule,
            ServerProxiesModule,
            SharedUsersModule,
            UIModule
        ],
        providers: [
            CurrencyPipe,
            DatePipe,
            DecimalPipe,
            PercentPipe
        ]
    })
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                httpInterceptorProviders
            ]
        };
    }
}
