<div class="grid">
    <div class="col-12 lg:col-6">
        <h2 *ngIf="totalBalance">Total Balance: {{totalBalance | currency}}</h2>
    </div>
    <div class="col-12 lg:col-6 right">
        <p-checkbox [(ngModel)]="showClosedAccounts" (onChange)="showClosedAccountsChanged()" label="Show Closed Accounts" [binary]="true"></p-checkbox>
        <p-checkbox [(ngModel)]="showZeroBalanceHoldings" (onChange)="showZeroBalanceHoldingsChanged()" label="Show Zero Balance Holdings" [disabled]="showClosedAccounts" [binary]="true" [style]="{ 'margin-left': '20px' }"></p-checkbox>
    </div>
</div>
<p-tabView>
    <p-tabPanel header="Trailing Returns" leftIcon="fas fa-money-bill-alt">
        <ng-template pTemplate="content">
            <my-account-returns-table [holdings]="filteredHoldings" [returnType]="'trailing'" [isBusy]="isBusy"></my-account-returns-table>
        </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Cumulative Returns" leftIcon="fas fa-money-check-alt">
        <ng-template pTemplate="content">
            <my-account-returns-table [holdings]="filteredHoldings" [returnType]="'cumulative'" [isBusy]="isBusy"></my-account-returns-table>
        </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Annual Returns" leftIcon="fas fa-donate">
        <ng-template pTemplate="content">
            <my-account-returns-table [holdings]="filteredHoldings" [returnType]="'annual'" [isBusy]="isBusy"></my-account-returns-table>
        </ng-template>
    </p-tabPanel>
    <p-tabPanel header="Transactions" leftIcon="fas fa-exchange-alt">
        <ng-template pTemplate="content">
            <my-transaction-list [accounts]="accounts" [isBusy]="isBusy"></my-transaction-list>
        </ng-template>
    </p-tabPanel>
<p-tabView>
