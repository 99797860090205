import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { SecuritiesProxy } from '../../shared/server-proxies';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';
import { SecurityCompareService } from '../compare/security-compare.service';
import * as models from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-security-list',
    templateUrl: './security-list.component.html'
})
export class SecurityListComponent extends BaseComponentDirective implements OnInit {
    constructor(private securitiesProxy: SecuritiesProxy, private securityCompare: SecurityCompareService) {
        super();
    }

    isBusy = false;
    filteredSecurities: models.Security[] = [];
    filter = '';
    private securities: models.Security[] = [];

    get canCompare() {
        return this.securities.length > 0 && this.securityCompare.securityCount > 0;
    }

    get isFiltered() {
        return this.filter && this.filter.trim().length > 0;
    }

    ngOnInit() {
        this.loadSecurities();
    }

    applyFilter() {
        this.filterSecurities();
    }

    clearFilter() {
        this.filter = '';
        this.filterSecurities();
    }

    private loadSecurities() {
        this.isBusy = true;
        this.securitiesProxy.getSecurities()
            .pipe(
                finalize(() => this.isBusy = false),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.securities = response.body.sort((a,b) => {
                        const headerA = a.assetTypeDisplayText;
                        const headerB = b.assetTypeDisplayText;

                        if(!headerA && headerB) {
                            return -1;
                        }
                        else if(headerA && !headerB) {
                            return 1;
                        }
                        else if(headerA === headerB) {
                            return a.displayText < b.displayText ? -1 : 1;
                        }
                        else {
                            return headerA < headerB ? -1 : 1;
                        }
                    });

                    this.securityCompare.refreshSecurities(this.securities);
                    this.filterSecurities();
                });
    }

    private filterSecurities() {
        if(this.isFiltered) {
            const filter = this.filter.trim().toLowerCase();
            this.filteredSecurities = this.securities
                .filter(s => s.displayText.toLowerCase().indexOf(filter) !== -1);
        }
        else {
            this.filteredSecurities = this.securities;
        }
    }
}
