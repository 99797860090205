<p-table [value]="securities" [loading]="isBusy" tableStyleClass="p-datatable-responsive-md">
    <ng-template pTemplate="body" let-security let-rowIndex="rowIndex">
        <tr class="p-widget-header p-rowgroup-header" *ngIf="shouldShowSecurityRowGroupHeader(rowIndex)">
            <td class="left">
                {{security.assetTypeDisplayText || 'Pending Categorization...'}}
            </td>
            <td class="width2 right">Price</td>
            <td class="width1 center">Expense<br />Ratio</td>
            <td class="width2 right">Total Assets<br />/Market Cap</td>
            <td *ngFor="let col of returnColumns" class="return-col right" innerHtml="{{col.label}}"></td>
            <td class="width1"></td>
        </tr>
        <tr>
            <td class="left">
                <span class="p-column-title">Name</span>
                <a [routerLink]="['/securities', security.securityId]">{{security.displayText}}</a>
            </td>
            <td class="right"  [pTooltip]="getPriceAsOfTooltip(security)">
                <span class="p-column-title">Price</span>
                {{security.price | currency}}
                <span *ngIf="security.priceChangePercent">
                    <br class="non-responsive-only" />
                    <span [ngClass]="getFinancialReturnCssClass(security.priceChangePercent)">{{security.priceChangePercent | percent:'1.2-2'}}</span>
                </span>
            </td>
            <td class="center">
                <span class="p-column-title">Expense Ratio</span>
                {{security.expenseRatio | percent:'1.2-2'}}
            </td>
            <td class="right">
                <span class="p-column-title">Total Assets/Market Cap</span>
                {{getTotalAssetsInBillions(security) | currency:undefined:undefined:'1.1-1'}}
                <span *ngIf="security.totalAssets">Bil</span>
            </td>
            <td *ngFor="let col of returnColumns" class="right">
                <span class="p-column-title">{{col.label}}</span>
                <span [ngClass]="getReturnCssClass(security, col.value)">{{getReturn(security, col.value) | percent:'1.2-2'}}</span>
            </td>
            <td class="center">
                <span class="status-icons">
                    <i *ngIf="showAddToCompareIcon(security)" class="fas fa-plus clickable add-icon" pTooltip="Add to Compare" (click)="addToCompare(security)"></i>
                    <i *ngIf="showRemoveFromCompareIcon(security)" class="fas fa-minus clickable remove-icon" pTooltip="Remove from Compare" (click)="removeFromCompare(security)"></i>
                    <i class="fas fa-trash-alt clickable delete-icon" pTooltip="Delete Security" (click)="confirmDeleteSecurity(security, rowIndex)"></i>
                </span>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="columnCount">
                {{isBusy ? 'Loading securities...' : 'No securities.'}}
            </td>
        </tr>
    </ng-template>
</p-table>
<p-confirmDialog key="security-returns-table-component" icon="pi pi-exclamation-triangle"></p-confirmDialog>
