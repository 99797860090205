import { Component, OnInit, Input } from '@angular/core';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { ChartData, ChartOptions, TooltipItem } from 'chart.js';
import { ChartService } from '../../../shared/ui/chart.service';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { Allocation, AllocationBenchmark, AllocationGroup } from '../analytics-base.directive';

@Component({
    selector: 'my-allocation',
    templateUrl: './allocation.component.html'
})
export class AllocationComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private chartService: ChartService,
        private currencyPipe: CurrencyPipe,
        private percentPipe: PercentPipe) {
        super();
    }

    @Input()
    set allocation(allocation: Allocation) {
        if(allocation) {
            this.myAllocation = allocation;
            this.chartData = this.getChartData(allocation.groups);
            this.selectedBenchmark = allocation.benchmarks.length > 0
                ? allocation.benchmarks[0]
                : undefined;
            this.total = allocation.total;
            this.allocations = allocation.groups;
        }
        else {
            this.myAllocation = undefined;
            this.chartData = undefined;
            this.selectedBenchmark = undefined;
            this.total = 0;
            this.allocations = [];
        }
    }

    get allocation(): Allocation {
        return this.myAllocation;
    }

    get hasBenchmarks() {
        return this.myAllocation.benchmarks.length > 0;
    }

    get hasMultipleBenchmarks() {
        return this.myAllocation.benchmarks.length > 1;
    }

    get columnCount() {
        return this.hasBenchmarks ? 4 : 3;
    }

    @Input()
    nameHeader = 'Name';
    @Input()
    showPercentageOfAssetClass = false;
    allocations: AllocationGroup[] = [];
    chartData: ChartData;
    chartOptions: ChartOptions;
    total = 0;
    isBusy = false;
    selectedBenchmark: AllocationBenchmark;
    private myAllocation: Allocation;

    ngOnInit() {
        const options = this.chartService.createDefaultChartOptions('pie');
        options.plugins.tooltip.callbacks.label = this.getTooltipLabel.bind(this);

        this.chartOptions = options;
    }

    getBenchmarkPercentage(index: number) {
        return this.selectedBenchmark ? this.selectedBenchmark.groups[index].percentage || 0 : 0;
    }

    getPercentOfAssetClassTooltip(allocation: AllocationGroup) {
        if(allocation.isEquity || allocation.isBond) {
            return `% of Total ${(allocation.isBond ? 'Bond' : 'Stock' )} Allocation`;
        }

        return undefined;
    }

    private getChartData(allocations: AllocationGroup[]) {
        const data: number[] = [];
        const backgroundColors: string[] = [];
        const labels: string[] = [];
        const colors = this.chartService.getChartColors();

        allocations.forEach((allocation, index) => {
            labels.push(allocation.name);
            data.push(allocation.amount);
            backgroundColors.push(colors[index % colors.length]);
        });

        const chartData: ChartData = {
            datasets: [{
                label: this.nameHeader,
                data: data,
                backgroundColor: backgroundColors
            }],
            labels: labels
        };

        return chartData;
    }

    private getTooltipLabel(tooltipItem: TooltipItem<'pie'>) {
        const dataset = tooltipItem.dataset;
        const data = dataset.data as number[];

        const label = tooltipItem.chart.data.labels[tooltipItem.dataIndex] as string;
        const value = data[tooltipItem.dataIndex];
        const total = data.reduce((runningTotal, currentValue) => runningTotal + currentValue, 0);
        const percentOfTotal = total === 0 ? 0 : (value / total);

        return ` ${label}: ${this.currencyPipe.transform(value)} - ${this.percentPipe.transform(percentOfTotal, '1.2-2')}`;
    }
}
