import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { finalize, tap } from 'rxjs/operators';
import { SecuritiesProxy } from '../../../shared/server-proxies';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { WhatIfService } from './what-if.service';
import * as models from '../../../shared/swagger-codegen/models';

interface TransactionSummary {
    firstTransactionDate: Date;
    accountIds: Set<number>;
    securityIds: Set<number>;
}

interface SecurityPricing {
    priceList: models.SecurityPriceHistory[];
    priceMap: Map<string, models.SecurityPriceHistory>;
}

interface SecurityPerformance {
    beginningBalance: number;
    endingBalance: number;
    percent: number;
    amount: number;
}

interface AccountHoldingTransactionWithPerformance extends models.AccountHoldingTransaction {
    performance?: SecurityPerformance;
    performanceAlternative?: SecurityPerformance;
    performanceDifference?: SecurityPerformance;
}

@Component({
    selector: 'my-what-if',
    templateUrl: './what-if.component.html'
})
export class WhatIfComponent extends BaseComponentDirective implements OnInit {
    constructor(private securityProxy: SecuritiesProxy, private whatIf: WhatIfService) {
        super();
    }

    isBusy: boolean;
    transactions: AccountHoldingTransactionWithPerformance[] = [];
    securityId: number;
    securityTicker: string;
    securityItems: SelectItem[] = [];
    firstTransactionDate: Date;
    transactionTotal: number;
    transactionCurrentValueTotal: number;
    transactionPerformanceAmount: number;
    transactionPerformancePercent: number;
    alternativeCurrentValueTotal: number;
    alternativePerformanceAmount: number;
    alternativePerformancePercent: number;
    differenceAmount: number;
    differencePercent: number;
    private securities: models.Security[] = [];
    private priceHistory = new Map<number, SecurityPricing>();

    ngOnInit() {
        this.loadSecurities()
            .subscribe(() => {
                this.getTransactions();
            });
    }

    removeAllFromCompare() {
        const transactionIds = this.transactions.map(t => t.accountHoldingTransactionId);
        transactionIds.forEach(id => this.whatIf.removeTransaction(id));
        this.getTransactions();
    }

    removeFromCompare(transaction: AccountHoldingTransactionWithPerformance) {
        this.whatIf.removeTransaction(transaction.accountHoldingTransactionId);
        this.getTransactions();
    }

    onSecurityChanged() {
        const security = this.securities.find(s => s.securityId === this.securityId);
        this.securityTicker = security && security.ticker ? security.ticker : 'vs';
        this.updatePerformance(this.securityId, this.firstTransactionDate);
    }

    private getTransactions() {
        const transactions = this.whatIf.getTransactions();
        const summary = this.getTransactionSummary(transactions);

        this.firstTransactionDate = summary.firstTransactionDate;
        this.transactions = transactions;

        summary.securityIds.forEach(securityId => this.updatePerformance(securityId, this.firstTransactionDate));
    }

    private getTransactionSummary(transactions: AccountHoldingTransactionWithPerformance[]) {
        const summary: TransactionSummary = {
            firstTransactionDate: new Date(9999, 12, 31),
            accountIds: new Set<number>(),
            securityIds: new Set<number>()
        };

        transactions.forEach(t => {
            const date = new Date(t.date);

            if(date < summary.firstTransactionDate) {
                summary.firstTransactionDate = date;
            }

            summary.accountIds.add(t.accountHolding.account.accountId);
            summary.securityIds.add(t.accountHolding.security.securityId);
        });

        return summary;
    }

    private loadSecurities() {
        this.isBusy = true;

        return this.securityProxy.getSecurities()
            .pipe(
                tap(response => {
                    this.securities = response.body;
                    this.securityItems = response.body.map(security => {
                        return {
                            value: security.securityId,
                            label: security.displayText
                        };
                    });
                    this.securityItems.sort((a,b) => a.label < b.label ? -1 : 1);
                }),
                finalize(() => this.isBusy = false),
                this.takeUntilUnsubscribed());
    }

    private updatePerformance(securityId: number, since: Date) {
        if(this.priceHistory.has(securityId)) {
            this.updateTransactionPerformance(securityId);
        }
        else {
            this.securityProxy.getPriceHistory(securityId, since)
                .pipe(
                    finalize(() => this.isBusy = false),
                    this.takeUntilUnsubscribed())
                .subscribe(response => {
                    const priceList = response.body;
                    const priceMap = new Map<string, models.SecurityPriceHistory>();

                    priceList.forEach(history => {
                        priceMap.set(history.date, history);
                    });

                    const pricing = {
                        priceList: priceList,
                        priceMap: priceMap
                    };
                    this.priceHistory.set(securityId, pricing);

                    this.updateTransactionPerformance(securityId);
                });
        }
    }

    private updateTransactionPerformance(securityId: number) {
        const pricing = this.priceHistory.get(securityId);
        const currentPrice = pricing.priceList.length > 0
            ? pricing.priceList[pricing.priceList.length - 1].close
            : undefined;

        this.transactions.forEach(transaction => {
            const transactionDate = new Date(transaction.date);

            if(transaction.accountHolding.security.securityId === securityId) {
                const beginningBalance = transaction.total;
                const adjustedShares = this.splitAdjustedShares(securityId, transaction.shares, transactionDate);
                const currentBalance = adjustedShares * currentPrice;
                transaction.performance = this.calculateCumulativePerformance(beginningBalance, currentBalance);
            }

            if(this.securityId === securityId && pricing) {
                const priceOnTranDate = pricing.priceMap.get(transaction.date);
                const beginningPrice = priceOnTranDate ? priceOnTranDate.close : undefined;
                const shares = transaction.total / beginningPrice;
                const adjustedShares = this.splitAdjustedShares(securityId, shares, transactionDate);
                const beginningBalance = beginningPrice * shares;
                const currentBalance = adjustedShares * currentPrice;
                transaction.performanceAlternative = this.calculateCumulativePerformance(beginningBalance, currentBalance);
                transaction.performanceDifference = {
                    beginningBalance: null,
                    endingBalance: null,
                    percent: transaction.performance.percent - transaction.performanceAlternative.percent,
                    amount: transaction.performance.amount - transaction.performanceAlternative.amount
                };
            }
        });

        this.summarizeTransactions();
    }

    private splitAdjustedShares(securityId: number, shares: number, sinceDate: Date) {
        let adjustedShares = shares;
        const security = this.securities.find(s => s.securityId === securityId);

        if(security) {
            const splits = security.stockSplits.filter(s => new Date(s.date) > sinceDate);
            splits.sort((a,b) => a.date < b.date ? -1 : 1);

            splits.forEach(s => {
               adjustedShares *= s.ratio;
            });
        }

        return adjustedShares;
    }

    private calculateCumulativePerformance(beginningBalance: number, endingBalance: number) {
        const performance: SecurityPerformance = {
            beginningBalance: beginningBalance,
            endingBalance: endingBalance,
            percent: endingBalance / beginningBalance - 1,
            amount: endingBalance - beginningBalance
        };

        return performance;
    }

    private summarizeTransactions() {
        this.transactionTotal = 0;
        this.transactionCurrentValueTotal = 0;

        if(this.securityId > 0) {
            this.alternativeCurrentValueTotal = 0;
        }

        this.transactions.forEach(transaction => {
            this.transactionTotal += transaction.total;

            if(transaction.performance) {
                this.transactionCurrentValueTotal += transaction.performance.endingBalance || 0;
            }

            if(this.securityId > 0 && transaction.performanceAlternative) {
                this.alternativeCurrentValueTotal += transaction.performanceAlternative.endingBalance || 0;
            }
        });

        const performance = this.calculateCumulativePerformance(this.transactionTotal, this.transactionCurrentValueTotal);
        this.transactionPerformanceAmount = performance.amount;
        this.transactionPerformancePercent = performance.percent;

        if(this.securityId > 0) {
            const alternativePerformance = this.calculateCumulativePerformance(
                this.transactionTotal,
                this.alternativeCurrentValueTotal);
            this.alternativePerformanceAmount = alternativePerformance.amount;
            this.alternativePerformancePercent = alternativePerformance.percent;

            this.differenceAmount = performance.amount - alternativePerformance.amount;
            this.differencePercent = performance.percent - alternativePerformance.percent;
        }
    }
}
