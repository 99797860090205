import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { securitiesRoutes } from './securities.routes';
import { SharedModule } from '../shared/shared.module';
import { SecurityCompareComponent } from './compare/security-compare.component';
import { SecurityCompareService } from './compare/security-compare.service';
import { SecurityAttributesComponent } from './detail/security-attributes.component';
import { SecurityDetailComponent } from './detail/security-detail.component';
import { SecurityListComponent } from './list/security-list.component';
import { SecurityReturnsTableComponent } from './list/security-returns-table.component';
import { SecuritySearchComponent } from './search/security-search.component';

@NgModule({
    imports: [
        securitiesRoutes,
        CommonModule,
        FormsModule,
        SharedModule
    ],
    declarations: [
        SecurityCompareComponent,
        SecurityAttributesComponent,
        SecurityDetailComponent,
        SecurityListComponent,
        SecurityReturnsTableComponent,
        SecuritySearchComponent
    ],
    providers: [
        SecurityCompareService
    ]
})
export class SecuritiesModule {

}
