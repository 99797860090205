import { Component, OnInit } from '@angular/core';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';
import { SecurityCompareService } from './security-compare.service';
import * as models from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-security-compare',
    templateUrl: './security-compare.component.html'
})
export class SecurityCompareComponent extends BaseComponentDirective implements OnInit {
    constructor(private securityCompare: SecurityCompareService) {
        super();
    }

    isBusy: boolean;
    securities: models.Security[] = [];

    ngOnInit() {
        this.getSecurities();
    }

    removeFromCompare(security: models.Security) {
        this.securityCompare.removeSecurity(security.securityId);
        this.getSecurities();
    }

    private getSecurities() {
        this.securities = this.securityCompare.getSecurities();
    }
}
