import { Injectable } from '@angular/core';
import { StorageService } from '../../../shared/core/storage.service';
import * as models from '../../../shared/swagger-codegen/models';

@Injectable()
export class WhatIfService {
    constructor(private storage: StorageService) {
        this.transactions = this.readTransactions() || [];
        this.transactionIds = new Set(this.transactions.map(t => t.accountHoldingTransactionId));
    }

    private compareTransactionsKey = 'what-if-transactions';
    private transactions: models.AccountHoldingTransaction[];
    private transactionIds: Set<number>;

    get transactionCount() {
        return this.transactions.length;
    }

    containsTransaction(accountHoldingTransactionId: number) {
        return this.transactionIds.has(accountHoldingTransactionId);
    }

    addTransaction(transaction: models.AccountHoldingTransaction) {
        if(!this.containsTransaction(transaction.accountHoldingTransactionId)) {
            this.transactionIds.add(transaction.accountHoldingTransactionId);
            this.transactions.push(transaction);
            this.transactionsUpdated();
        }
    }

    removeTransaction(accountHoldingTransactionId: number) {
        if(this.containsTransaction(accountHoldingTransactionId)) {
            const i = this.transactions.findIndex(t => t.accountHoldingTransactionId === accountHoldingTransactionId);

            if(i !== -1) {
                this.transactionIds.delete(accountHoldingTransactionId);
                this.transactions.splice(i, 1);
                this.transactionsUpdated();
            }
        }
    }

    getTransactions() {
        return this.transactions;
    }

    clearTransactions() {
        this.transactions.length = 0;
        this.transactionIds.clear();
        this.deleteTransactions();
    }

    private transactionsUpdated() {
        this.sortTransactions();
        this.writeTransactions();
    }

    private sortTransactions() {
        this.transactions.sort((a,b) => {
           if(a.date === b.date) {
               return a.accountHolding.security.name < b.accountHolding.security.name ? -1 : 1;
           }
           else {
               return a.date < b.date ? 1 : -1;
           }
        });
    }

    private readTransactions() {
        return this.storage.getSessionItem(this.compareTransactionsKey) as models.AccountHoldingTransaction[];
    }

    private writeTransactions() {
        this.storage.setSessionItem(this.compareTransactionsKey, this.transactions);
    }

    private deleteTransactions() {
        this.storage.deleteSessionItem(this.compareTransactionsKey);
    }
}
