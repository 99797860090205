<div class="grid">
    <div class="col-12">
        <div *ngFor="let group of filteredGroups">
            <p-table [value]="group.fields" styleClass="p-datatable-striped" tableStyleClass="p-datatable-responsive-md">
                <ng-template pTemplate="header">
                    <tr *ngIf="group.label && group.label.trim().length > 0">
                        <th [attr.colspan]="securities.length + 1">{{group.label}}</th>
                    </tr>
                    <tr style="vertical-align: baseline">
                        <th></th>
                        <th class="width15" *ngFor="let security of securities" class="center">
                            {{security.name}}<br />
                            {{security.ticker}}<br />
                            <i *ngIf="enableDelete" class="fas fa-times clickable remove-icon" pTooltip="Remove from Compare" (click)="removeFromCompare(security)"></i>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-field>
                    <tr>
                        <td>
                            <strong>{{field.label}}</strong>
                            <i *ngIf="field.tooltip" class="fas fa-info-circle help-icon clickable" [pTooltip]="field.tooltip" tooltipPosition="right" style="margin-left: 5px;"></i>
                        </td>
                        <td *ngFor="let security of securities" [ngClass]="getFieldCssClass(field, security)" [innerHtml]="getFormattedFieldValue(field, security)">
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="securities.length + 1">
                            No securities to compare.
                        </td>
                    </tr>
                </ng-template>
        </p-table>
        </div>
    </div>
</div>
