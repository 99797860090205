<div class="grid">
    <div class="col-12 lg:col-4">
        <p-table [value]="allocations" [loading]="isBusy" styleClass="p-datatable-striped">
            <ng-template pTemplate="header">
                <tr valign="bottom">
                    <th class="left">{{nameHeader}}</th>
                    <th class="width3 right">Amount</th>
                    <th class="width5 center">%</th>
                    <th class="width3 right" *ngIf="hasBenchmarks">
                        <p-dropdown *ngIf="hasMultipleBenchmarks" [options]="allocation.benchmarks" [(ngModel)]="selectedBenchmark" optionLabel="name"></p-dropdown>
                        <span *ngIf="!hasMultipleBenchmarks && selectedBenchmark">{{selectedBenchmark.name}}</span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-allocation let-rowIndex="rowIndex">
                <tr>
                    <td class="left">
                        <span class="p-column-title">Name</span>
                        {{allocation.name}}
                    </td>
                    <td class="right">
                        <span class="p-column-title">Amount</span>
                        {{allocation.amount | currency}}
                    </td>
                    <td class="center">
                        <span class="p-column-title">% of Total and/or Asset Class</span>
                        <span pTooltip="% of Total Balance">{{allocation.percentage | percent:'1.2-2'}}</span>
                        <span *ngIf="showPercentageOfAssetClass && allocation.percentageOfAssetClass" [pTooltip]="getPercentOfAssetClassTooltip(allocation)">
                            / {{allocation.percentageOfAssetClass | percent:'1.2-2'}}
                        </span>
                    </td>
                    <td class="right" *ngIf="hasBenchmarks">
                        <span class="p-column-title">Benchmark %</span>
                        {{getBenchmarkPercentage(rowIndex) | percent:'1.2-2'}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                    <td>
                        <strong>Total</strong>
                    </td>
                    <td class="right">
                        <strong>{{total | currency}}</strong>
                    </td>
                    <td class="center">
                        <strong>{{1 | percent:'1.2-2'}}</strong>
                    </td>
                    <td *ngIf="hasBenchmarks"></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="columnCount">
                        No allocations.
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="col-12 lg:col-8">
        <p-chart type="pie" [data]="chartData" [options]="chartOptions" responsive="true"></p-chart>
    </div>
</div>
