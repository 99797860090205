import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { AuthGuard } from './shared/auth/auth-guard.service';
import { AppMainComponent } from './app.main.component';
import { AccountListComponent } from './accounts/list/account-list.component';

export const routes: Routes = [
    {
        path: '',
        component: AppMainComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: AccountListComponent
            }
        ]
    }
];

export const appRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(
    routes,
    {
        scrollPositionRestoration: 'enabled'
    }
);
