import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, from as observableFrom } from 'rxjs';

@Injectable()
export class HttpClientWrapperService {
    constructor(private httpClient: HttpClient) {
    }

    /* eslint-disable @typescript-eslint/naming-convention */
    private defaultHeaders: HttpHeaders = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json'
    });

    delete<TResponseBody>(url: string): Observable<HttpResponse<TResponseBody>> {
        return this.httpClient.delete<TResponseBody>(
            url,
            {
                observe: 'response',
                headers: this.defaultHeaders
            }
        );
    }

    get<TResponseBody>(url: string): Observable<HttpResponse<TResponseBody>> {
        return this.httpClient.get<TResponseBody>(
            url,
            {
                observe: 'response',
                headers: this.defaultHeaders
            }
        );
    }

    head<TResponseBody>(url: string): Observable<HttpResponse<TResponseBody>> {
        return this.httpClient.head<TResponseBody>(
            url,
            {
                observe: 'response',
                headers: this.defaultHeaders
            }
        );
    }

    jsonp<TResponseBody>(url: string, callbackParam: string): Observable<TResponseBody> {
        return this.httpClient.jsonp<TResponseBody>(url, callbackParam);
    }

    options<TResponseBody>(url: string): Observable<HttpResponse<TResponseBody>> {
        return this.httpClient.options<TResponseBody>(
            url,
            {
                observe: 'response',
                headers: this.defaultHeaders
            }
        );
    }

    patch<TResponseBody>(url: string, data?: any): Observable<HttpResponse<TResponseBody>> {
        return this.httpClient.patch<TResponseBody>(
            url,
            JSON.stringify(data),
            {
                observe: 'response',
                headers: this.defaultHeaders
            }
        );
    }

    post<TResponseBody>(url: string, data?: any): Observable<HttpResponse<TResponseBody>> {
        return this.httpClient.post<TResponseBody>(
            url,
            JSON.stringify(data),
            {
                observe: 'response',
                headers: this.defaultHeaders
            }
        );
    }

    put<TResponseBody>(url: string, data?: any): Observable<HttpResponse<TResponseBody>> {
        return this.httpClient.put<TResponseBody>(
            url,
            JSON.stringify(data),
            {
                observe: 'response',
                headers: this.defaultHeaders
            }
        );
    }

    createObservableHttpResponse<TResponseBody>(data: TResponseBody): Observable<HttpResponse<TResponseBody>> {
        const response = new HttpResponse<TResponseBody>(
            {
                body: data,
                status: 200,
                statusText: 'OK'
            });

        return observableFrom([response]);
    }
}
