import { DatePipe } from '@angular/common';
import { OnDestroy, Directive } from '@angular/core';
import { Subject, MonoTypeOperatorFunction } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class BaseComponentDirective implements OnDestroy  {
    private unsubscribeSubject: Subject<void> = new Subject();

    ngOnDestroy() {
        this.onDestroy();
        this.unsubscribe();
    }

    getFinancialReturnCssClass(performance: number) {
        /* eslint-disable @typescript-eslint/naming-convention */
        return {
            'positive-return': performance > 0,
            'negative-return': performance < 0
        };
        /* eslint-enable @typescript-eslint/naming-convention */
    }

    getAllocationDifferenceCssClass(difference: number, strict = true) {
        const abs = Math.abs(difference * 100);
        const green = strict ? abs === 0 : false;
        const amber1 = strict ? abs > 0 && abs <= 2 : abs > 2 && abs <= 3;
        const amber2 = strict ? abs > 2 && abs < 5 : abs > 3 && abs < 5;
        const red = strict ? abs >= 5 : abs >= 5;

        /* eslint-disable @typescript-eslint/naming-convention */
        return {
            'status-green': green,
            'status-amber1': amber1,
            'status-amber2': amber2,
            'status-red': red
        };
        /* eslint-enable @typescript-eslint/naming-convention */
    }

    getAsOfDateTooltip(asOfDate: Date, datePipe: DatePipe) {
        if(!asOfDate) {
            return undefined;
        }

        const dateString = datePipe.transform(asOfDate + 'Z', 'EEE, M/d/yy');
        const timeString = datePipe.transform(asOfDate + 'Z', 'shortTime');

        return `As of:\n${dateString}\nat ${timeString}`;
    }

    protected onDestroy() {
    }

    protected unsubscribe() {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }

    protected takeUntilUnsubscribed<T>(): MonoTypeOperatorFunction<T> {
        return takeUntil(this.unsubscribeSubject);
    }
}
