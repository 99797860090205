import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsBaseDirective } from '../analytics-base.directive';
import * as models from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-region-allocation',
    templateUrl: './region-allocation.component.html'
})
export class RegionAllocationComponent extends AnalyticsBaseDirective implements OnInit {
    constructor() {
        super();

        this.groupPercentageAccessors['North America'] = security => security.portfolio.regionNorthAmerica;
        this.groupPercentageAccessors['United Kingdom'] = security => security.portfolio.regionUnitedKingdom;
        this.groupPercentageAccessors['Latin America'] = security => security.portfolio.regionLatinAmerica;
        this.groupPercentageAccessors['Europe Developed'] = security => security.portfolio.regionEuropeDeveloped;
        this.groupPercentageAccessors['Europe Emerging'] = security => security.portfolio.regionEuropeEmerging;
        this.groupPercentageAccessors['Africa/Middle East'] = security => security.portfolio.regionAfricaMiddleEast;
        this.groupPercentageAccessors['Japan'] = security => security.portfolio.regionJapan;
        this.groupPercentageAccessors['Australasia'] = security => security.portfolio.regionAustralasia;
        this.groupPercentageAccessors['Asia Developed'] = security => security.portfolio.regionAsiaDeveloped;
        this.groupPercentageAccessors['Asia Emerging'] = security => security.portfolio.regionAsiaEmerging;
    }

    @Input()
    set accounts(accounts: models.AccountAndHoldings[]) {
        this.myAccounts = accounts;
        this.loadAllocations(accounts, this.benchmarks);
    }

    get accounts(): models.AccountAndHoldings[] {
        return this.myAccounts;
    }

    @Input()
    set benchmarks(benchmarks: models.Security[]) {
        this.myBenchmarks = benchmarks;
        this.loadAllocations(this.accounts, benchmarks);
    }

    get benchmarks(): models.Security[] {
        return this.myBenchmarks;
    }
    private myBenchmarks: models.Security[] = [];
    private myAccounts: models.AccountAndHoldings[] = [];

    ngOnInit() {
    }

    protected loadGroupNamesAndAmounts(holding: models.AccountHolding, groupNamesAndAmounts: [string, number][]) {
        this.loadGroupNamesAndAmountsUsingPercentageAccessors(holding, groupNamesAndAmounts);
    }
}
