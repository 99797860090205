import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { AuthGuard } from '../shared/auth/auth-guard.service';
import { AppMainComponent } from '../app.main.component';

export const routes: Routes = [
    {
        path: 'users',
        component: AppMainComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/accounts'
            }
        ]
    }
];

export const usersRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
