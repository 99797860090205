import { Component, Input } from '@angular/core';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';

export interface StockStyles {
    largeValue: number;
    largeBlend: number;
    largeGrowth: number;
    mediumValue: number;
    mediumBlend: number;
    mediumGrowth: number;
    smallValue: number;
    smallBlend: number;
    smallGrowth: number;
}

@Component({
    selector: 'my-stock-styles-nine-box',
    templateUrl: './stock-styles-nine-box.component.html',
    styleUrls: [
        './stock-styles-nine-box.component.scss'
    ]
})
export class StockStylesNineBoxComponent extends BaseComponentDirective {
    constructor() {
        super();
    }

    @Input()
    stockStyleAmounts: StockStyles;
    @Input()
    stockStylePercentages: StockStyles;
    @Input()
    benchmarkStockStylePercentages: StockStyles;

    get hasBenchmarks() {
        return this.benchmarkStockStylePercentages !== undefined;
    }
}
