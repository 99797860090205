import { NgModule } from '@angular/core';
import { CurrentUserService } from './current-user.service';

@NgModule(
    {
        imports: [
        ],
        exports: [
        ],
        declarations: [
        ],
        providers: [
            CurrentUserService
        ]
    })
export class SharedUsersModule {
}
