import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';

import { BalanceHistoryDialogComponent } from './balance-history-dialog.component';

@Injectable()
export class BalanceHistoryDialogService {
    constructor(private dialogService: DialogService) {

    }

    open(accountId: number, securityId: number, header = 'Balance History') {

        return this.dialogService.open(
            BalanceHistoryDialogComponent,
            {
                data: {
                    accountId: accountId,
                    securityId: securityId
                },
                header: header,
                width: '95%',
                height: '95%',
                contentStyle: {
                    overflow: 'auto',
                    width: '100%',
                    height: '90%'
                }
            });
    }
}
