import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { StockStyles } from '../stock-styles/stock-styles-nine-box.component';
import { AccountWithHoldingAllocations } from './account-rebalance.component';
import * as models from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-rebalance-stock-styles',
    templateUrl: './rebalance-stock-styles.component.html'
})
export class RebalanceStockStylesComponent extends BaseComponentDirective implements OnChanges {
    constructor() {
        super();
    }

    @Input()
    accountsWithAllocations: AccountWithHoldingAllocations[] = [];
    @Input()
    securityHoldings: { [securityId: number]: models.SecurityHolding[] } = {};
    @Input()
    benchmarks: models.Security[] = [];

    get hasBenchmarks() {
        return this.benchmarks && this.benchmarks.length > 0;
    }

    currentStockStyleAmounts: StockStyles;
    currentStockStylePercentages: StockStyles;
    rebalanceStockStyleAmounts: StockStyles;
    rebalanceStockStylePercentages: StockStyles;
    benchmarkStockStylePercentages: StockStyles;
    isBusy = false;

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ngOnChanges(changes: SimpleChanges) {
        this.loadStockStyles();
        this.loadBenchmarkStockStyles();
    }

    private loadStockStyles() {
        this.currentStockStyleAmounts = this.createStockStyles();
        this.rebalanceStockStyleAmounts = this.createStockStyles();
        const amounts = [this.currentStockStyleAmounts, this.rebalanceStockStyleAmounts];
        let total = 0;

        this.accountsWithAllocations.forEach(account => {
            total += account.balance;

            account.holdings.forEach(holding => {
                const portfolio = holding.security.portfolio;

                if(portfolio) {
                    const balances = [holding.currentBalance, holding.rebalanceBalance];

                    for(let i = 0; i < amounts.length && i < balances.length; i++) {
                        const amount = amounts[i];
                        const balance = balances[i];

                        amount.largeValue += (portfolio.stockStyleLargeValue || 0) * balance;
                        amount.largeBlend += (portfolio.stockStyleLargeBlend || 0) * balance;
                        amount.largeGrowth += (portfolio.stockStyleLargeGrowth || 0) * balance;
                        amount.mediumValue += (portfolio.stockStyleMediumValue || 0) * balance;
                        amount.mediumBlend += (portfolio.stockStyleMediumBlend || 0) * balance;
                        amount.mediumGrowth += (portfolio.stockStyleMediumGrowth || 0) * balance;
                        amount.smallValue += (portfolio.stockStyleSmallValue || 0) * balance;
                        amount.smallBlend += (portfolio.stockStyleSmallBlend || 0) * balance;
                        amount.smallGrowth += (portfolio.stockStyleSmallGrowth || 0) * balance;
                    }
                }
            });
        });

        this.currentStockStylePercentages = this.createStockStyles();
        this.rebalanceStockStylePercentages = this.createStockStyles();
        const percentages = [this.currentStockStylePercentages, this.rebalanceStockStylePercentages];

        for(let i = 0; i < amounts.length && i < percentages.length; i++) {
            const amount = amounts[i];
            const percentage = percentages[i];

            percentage.largeValue = amount.largeValue / total;
            percentage.largeBlend = amount.largeBlend / total;
            percentage.largeGrowth = amount.largeGrowth / total;
            percentage.mediumValue = amount.mediumValue / total;
            percentage.mediumBlend = amount.mediumBlend / total;
            percentage.mediumGrowth = amount.mediumGrowth / total;
            percentage.smallValue = amount.smallValue / total;
            percentage.smallBlend = amount.smallBlend / total;
            percentage.smallGrowth = amount.smallGrowth / total;
        }
    }

    private loadBenchmarkStockStyles() {
        this.benchmarkStockStylePercentages = this.createStockStyles();

        if(this.hasBenchmarks) {
            const portfolio = this.benchmarks[0].portfolio;

            if(portfolio) {
                this.benchmarkStockStylePercentages.largeValue = portfolio.stockStyleLargeValue || 0;
                this.benchmarkStockStylePercentages.largeBlend = portfolio.stockStyleLargeBlend || 0;
                this.benchmarkStockStylePercentages.largeGrowth = portfolio.stockStyleLargeGrowth || 0;
                this.benchmarkStockStylePercentages.mediumValue = portfolio.stockStyleMediumValue || 0;
                this.benchmarkStockStylePercentages.mediumBlend = portfolio.stockStyleMediumBlend || 0;
                this.benchmarkStockStylePercentages.mediumGrowth = portfolio.stockStyleMediumGrowth || 0;
                this.benchmarkStockStylePercentages.smallValue = portfolio.stockStyleSmallValue || 0;
                this.benchmarkStockStylePercentages.smallBlend = portfolio.stockStyleSmallBlend || 0;
                this.benchmarkStockStylePercentages.smallGrowth = portfolio.stockStyleSmallGrowth || 0;
            }
        }
    }

    private createStockStyles() {
        const styles: StockStyles = {
            largeValue: 0,
            largeBlend: 0,
            largeGrowth: 0,
            mediumValue: 0,
            mediumBlend: 0,
            mediumGrowth: 0,
            smallValue: 0,
            smallBlend: 0,
            smallGrowth: 0
        };

        return styles;
    }
}
