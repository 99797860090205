import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { authenticationRoutes } from './authentication.routes';

import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';

@NgModule({
    imports: [
        SharedModule,
        authenticationRoutes
    ],
    declarations: [
        LoginComponent,
        LogoutComponent
    ],
    providers: [
    ]
})
export class AuthenticationModule {

}
