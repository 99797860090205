import { BaseComponentDirective } from '../../shared/ui/base-component.directive';
import * as models from '../../shared/swagger-codegen/models';
import { Directive } from '@angular/core';

export interface AllocationGroup {
    name: string;
    amount: number;
    percentage: number;
    percentageOfAssetClass?: number;
    isEquity?: boolean;
    isBond?: boolean;
}

export interface AllocationBenchmark {
    name: string;
    groups: AllocationGroup[];
}

export interface Allocation {
    total: number;
    groups: AllocationGroup[];
    benchmarks: AllocationBenchmark[];
}

export interface AllocationGroupConfig {
    name: string;
    percentageAccessor: (security: models.Security) => number;
    isEquity?: boolean;
    isBond?: boolean;
}

@Directive()
export abstract class AnalyticsBaseDirective extends BaseComponentDirective {

    allocation: Allocation;
    isBusy = false;
    protected groupPercentageAccessors: { [groupName: string]: (security: models.Security) => number } = {};
    protected assetAllocationGroupConfigs: AllocationGroupConfig[];
    protected marketCapAllocationGroupConfigs: AllocationGroupConfig[];
    protected regionAllocationGroupConfigs: AllocationGroupConfig[];
    protected sectorAllocationGroupConfigs: AllocationGroupConfig[];

    protected loadAllocationGroupConfigs() {
        this.assetAllocationGroupConfigs = [
            {
                name: 'Cash',
                percentageAccessor: security => security.portfolio.assetsCash
            },
            {
                name: 'US Stocks',
                percentageAccessor: security => security.portfolio.assetsUsStocks,
                isEquity: true
            },
            {
                name: 'Non-US Stocks (Developed)',
                percentageAccessor: security => security.portfolio.regionNonUsDeveloped,
                isEquity: true
            },
            {
                name: 'Non-US Stocks (Emerging)',
                percentageAccessor: security => security.portfolio.regionEmerging,
                isEquity: true
            },
            {
                name: 'US Bonds',
                percentageAccessor: security => security.category.toLowerCase().indexOf('world bond') === -1
                    ? security.portfolio.assetsBonds
                    : 0,
                isBond: true
            },
            {
                name: 'Non-US Bonds',
                percentageAccessor: security => security.category.toLowerCase().indexOf('world bond') >= 0
                    ? security.portfolio.assetsBonds
                    : 0,
                isBond: true
            },
            {
                name: 'Other',
                percentageAccessor: security => security.portfolio.assetsOther
            }
        ];

        this.marketCapAllocationGroupConfigs = [
            {
                name: 'Giant',
                percentageAccessor: security => security.portfolio.marketCapGiant
            },
            {
                name: 'Large',
                percentageAccessor: security => security.portfolio.marketCapLarge
            },
            {
                name: 'Medium',
                percentageAccessor: security => security.portfolio.marketCapMedium
            },
            {
                name: 'Small',
                percentageAccessor: security => security.portfolio.marketCapSmall
            },
            {
                name: 'Micro',
                percentageAccessor: security => security.portfolio.marketCapMicro
            }
        ];

        this.regionAllocationGroupConfigs = [
            {
                name: 'North America',
                percentageAccessor: security => security.portfolio.regionNorthAmerica
            },
            {
                name: 'United Kingdom',
                percentageAccessor: security => security.portfolio.regionUnitedKingdom
            },
            {
                name: 'Latin America',
                percentageAccessor: security => security.portfolio.regionLatinAmerica
            },
            {
                name: 'Europe Developed',
                percentageAccessor: security => security.portfolio.regionEuropeDeveloped
            },
            {
                name: 'Europe Emerging',
                percentageAccessor: security => security.portfolio.regionEuropeEmerging
            },
            {
                name: 'Africa/Middle East',
                percentageAccessor: security => security.portfolio.regionAfricaMiddleEast
            },
            {
                name: 'Japan',
                percentageAccessor: security => security.portfolio.regionJapan
            },
            {
                name: 'Australasia',
                percentageAccessor: security => security.portfolio.regionAustralasia
            },
            {
                name: 'Asia Developed',
                percentageAccessor: security => security.portfolio.regionAsiaDeveloped
            },
            {
                name: 'Asia Emerging',
                percentageAccessor: security => security.portfolio.regionAsiaEmerging
            },
        ];

        this.sectorAllocationGroupConfigs = [
            {
                name: 'Basic Materials',
                percentageAccessor: security => security.portfolio.sectorBasicMaterials
            },
            {
                name: 'Consumer Cyclical',
                percentageAccessor: security => security.portfolio.sectorConsumerCyclical
            },
            {
                name: 'Financial Services',
                percentageAccessor: security => security.portfolio.sectorFinancialServices
            },
            {
                name: 'Real Estate',
                percentageAccessor: security => security.portfolio.sectorRealEstate
            },
            {
                name: 'Communication Services',
                percentageAccessor: security => security.portfolio.sectorCommunicationServices
            },
            {
                name: 'Energy',
                percentageAccessor: security => security.portfolio.sectorEnergy
            },
            {
                name: 'Industrials',
                percentageAccessor: security => security.portfolio.sectorIndustrials
            },
            {
                name: 'Technology',
                percentageAccessor: security => security.portfolio.sectorTechnology
            },
            {
                name: 'Consumer Defensive',
                percentageAccessor: security => security.portfolio.sectorConsumerDefensive
            },
            {
                name: 'Healthcare',
                percentageAccessor: security => security.portfolio.sectorHealthcare
            },
            {
                name: 'Utilities',
                percentageAccessor: security => security.portfolio.sectorUtilities
            }
        ];
    }

    protected loadAllocations(accounts: models.AccountAndHoldings[], benchmarks: models.Security[] = []) {
        const groupsAndAmounts = this.loadGroupNamesAndAmounts.bind(this);
        this.allocation = this.getAllocation(accounts, benchmarks, groupsAndAmounts);
    }

    protected abstract loadGroupNamesAndAmounts(holding: models.AccountHolding, groupsAndAmounts: [string, number][]);

    protected loadGroupNamesAndAmountsUsingPercentageAccessors(
        holding: models.AccountHolding,
        groupNamesAndAmounts: [string, number][]) {

        const balance = holding.balance;

        Object.keys(this.groupPercentageAccessors)
            .forEach(groupName => {
                const percentageAccessor = this.groupPercentageAccessors[groupName];
                const percentage = percentageAccessor(holding.security);

                groupNamesAndAmounts.push([groupName, balance * percentage]);
            });
    }

    protected getAllocation(
        accounts: models.AccountAndHoldings[],
        benchmarks: models.Security[],
        loadGroupNamesAndAmounts: (holding: models.AccountHolding, groupsAndAmounts: [string, number][]) => void) {

        const allocation: Allocation = {
            total: 0,
            groups: [],
            benchmarks: []
        };
        const groups: {[name: string]: AllocationGroup} = {};
        const groupNamesAndAmounts: [string, number][] = [];

        accounts.forEach(account => {
           account.holdings.forEach(holding => {
               groupNamesAndAmounts.length = 0;
               loadGroupNamesAndAmounts(holding, groupNamesAndAmounts);

               groupNamesAndAmounts.forEach(groupNameAndAmount => {
                   const groupName = groupNameAndAmount[0] || '?';
                   const amount = groupNameAndAmount[1] || 0;

                   let groupAllocation = groups[groupName];

                   if(!groupAllocation){
                       groupAllocation = {
                           name: groupName,
                           amount: 0,
                           percentage: 0
                       };
                       allocation.groups.push(groupAllocation);
                   }

                   groupAllocation.amount += amount;
                   groups[groupName] = groupAllocation;

                   allocation.total += amount;
               });
           });
        });

        allocation.groups.forEach(group => group.percentage = group.amount / allocation.total);
        allocation.groups = allocation.groups.sort((a,b) => {
           if(a.amount === b.amount) {
               return a.name < b.name ? -1 : 1;
           }
           else {
               return a.amount < b.amount ? 1 : -1;
           }
        });

        this.loadBenchmarkAllocations(allocation, benchmarks);
        this.prepareFinalAllocation(allocation);

        return allocation;
    }

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected prepareFinalAllocation(allocation: Allocation) {
    }

    private loadBenchmarkAllocations(allocation: Allocation, benchmarks: models.Security[]) {
        benchmarks.forEach(security => {
            const benchmark = {
                name: security.name.length > 10 ? security.ticker : security.name,
                groups: []
            };

            allocation.groups.forEach(group => {
                const value = this.getAllocationGroupPercentage(group.name, security);

                if(value !== undefined) {
                    benchmark.groups.push({
                        name: group.name,
                        amount: 0,
                        percentage: value
                    });
                }
            });

            if(benchmark.groups.length > 0) {
                allocation.benchmarks.push(benchmark);
            }
        });
    }

    private getAllocationGroupPercentage(groupName: string, security: models.Security): number {
        const percentageAccessor = this.groupPercentageAccessors[groupName];
        let percentage: number;

        if(percentageAccessor) {
            percentage = percentageAccessor(security);
        }

        return percentage;
    }
}
