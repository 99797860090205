<div class="grid">
    <form>
        <div class="formgroup-inline">
            <div class="field">
                <button [routerLink]="'/securities/new'" pButton icon="fas fa-plus" label="Add Security"></button>
            </div>
            <div class="field">
                <input id="filter" name="filter" [(ngModel)]="filter" pInputText placeholder="Filter..." class="width10">
            </div>
            <div class="field">
                <button pButton type="button" label="Apply Filter" (click)="applyFilter()"></button>
            </div>
            <div class="field" *ngIf="isFiltered">
                <button pButton type="button" label="Clear Filter" (click)="clearFilter()"></button>
            </div>
            <div class="field" *ngIf="canCompare">
                <a [routerLink]="'/securities/compare'">Compare Securities</a>
            </div>
        </div>
    </form>
</div>

<p-tabView>
    <p-tabPanel header="Trailing Returns" leftIcon="fas fa-money-bill-alt">
        <my-security-returns-table [securities]="filteredSecurities" [returnType]="'trailing'" [isBusy]="isBusy"></my-security-returns-table>
    </p-tabPanel>
    <p-tabPanel header="Annual Returns" leftIcon="fas fa-donate">
        <my-security-returns-table [securities]="filteredSecurities" [returnType]="'annual'" [isBusy]="isBusy"></my-security-returns-table>
    </p-tabPanel>
<p-tabView>
