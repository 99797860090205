import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { AuthGuard } from '../shared/auth/auth-guard.service';
import { AppMainComponent } from '../app.main.component';
import { AccountAnalyticsComponent } from './analytics/account-analytics.component';
import { AccountListComponent } from './list/account-list.component';
import { TransactionImportComponent } from './transactions/import/transaction-import.component';
import { TransactionListComponent } from './transactions/list/transaction-list.component';
import { WhatIfComponent } from './transactions/what-if/what-if.component';

export const routes: Routes = [
    {
        path: 'accounts',
        component: AppMainComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: AccountListComponent,
                data: {
                    title: 'Accounts'
                }
            },
            {
                path: 'analytics',
                component: AccountAnalyticsComponent,
                data: {
                    title: 'Account Analytics'
                }
            },
            {
                path: 'transactions/import',
                component: TransactionImportComponent,
                data: {
                    title: 'Transaction Import'
                }
            },
            {
                path: 'transactions/what-if',
                component: WhatIfComponent,
                data: {
                    title: 'What If?'
                }
            },
            {
                path: ':accountId/transactions',
                component: TransactionListComponent,
                data: {
                    title: 'Transactions'
                }
            }
        ]
    }
];

export const accountsRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
