import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { SessionService } from '../../shared/auth/session.service';
import { AuthenticationProxy, UsersProxy } from '../../shared/server-proxies';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';
import { Message } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import * as models from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-login',
    templateUrl: './login.component.html'
})
export class LoginComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private authenticationProxy: AuthenticationProxy,
        private usersProxy: UsersProxy,
        private session: SessionService,
        private router: Router) {
        super();
        this.messages = [];
    }

    @ViewChild('loginForm', { static: true })
    loginForm: NgForm;
    credentials: models.AuthUserTokenObtainPair = {
        email: '',
        password: ''
    };
    rememberMe = false;
    hasRememberMe: boolean;
    messages: Message[];
    isLoading: boolean;
    isLoggingInWithToken: boolean;

    ngOnInit() {
        const token = this.session.getRefreshToken();

        if(token) {
            this.loginWithToken(token);
        }
    }

    login() {
        this.messages = [];
        this.isLoading = true;
        this.authenticationProxy.login(this.credentials)
            .pipe(
                finalize(
                    () => {
                        this.isLoading = false;
                    }),
                this.takeUntilUnsubscribed()
            )
            .subscribe(
                response => {
                    this.messages.push({ severity: 'info', summary: '', detail: 'Login suceeded.' });
                    this.handleSuccessfulLogin(response.body, this.rememberMe);
                },
                error => {
                    if(error.status === 401) {
                        this.messages = [{ severity: 'warn', summary: '', detail: 'Login failed.' }];
                    }
                    else {
                        this.messages = [{ severity: 'error', summary: '', detail: 'An error occurred.' }];
                    }
                });
    }

    getEmailAutoFocus() {
        return this.hasRememberMe ? null : 'autofocus';
    }

    getPasswordAutoFocus() {
        return this.hasRememberMe ? 'autofocus' : null;
    }

    private loginWithToken(token: string) {
        this.isLoading = true;
        this.isLoggingInWithToken = true;

        const refresh: models.TokenRefresh = {
            refresh: token
        };

        this.authenticationProxy.loginWithToken(refresh)
            .pipe(
                finalize(() => this.isLoading = false),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.handleSuccessfulLogin(response.body, true);
                },
                error => {
                    if(error.status === 401) {
                        this.session.disposeSession();
                    }

                    this.isLoggingInWithToken = false;
                });
    }

    private handleSuccessfulLogin(result: models.TokenPair, rememberMe: boolean) {
        this.session.setTokens(result, rememberMe);
        this.usersProxy.getCurrentUser()
            .pipe(
                finalize(() => this.isLoading = false),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.session.setUser(response.body);
                    this.router.navigateByUrl('/');
                },
                () => {
                    this.messages.push({ severity: 'error', summary: '', detail: 'An error occurred.' });
                });
    }
}
