<h2>Transaction Compare</h2>
<div class="grid">
    <div class="field col-12 lg:col-3">
        <span class="p-float-label">
            <p-dropdown name="accountId" [(ngModel)]="securityId" [options]="securityItems" styleClass="width-full" [autoDisplayFirst]="false" (onChange)="onSecurityChanged()"></p-dropdown>
            <label>Compare Returns To</label>
        </span>
    </div>
    <div class="col-12">
        <p-table [value]="transactions" tableStyleClass="p-datatable-responsive-md">
            <ng-template pTemplate="header">
                <tr>
                    <th rowspan="2" class="width2">Date</th>
                    <th rowspan="2" class="left">Holding</th>
                    <th rowspan="2" class="width3 right">Shares</th>
                    <th rowspan="2" class="width2 right">Price</th>
                    <th rowspan="2" class="width2 right">Fees</th>
                    <th rowspan="2" class="width3 right">Total</th>
                    <th colspan="3" class="width14">Transaction</th>
                    <th colspan="3" class="width14">{{securityTicker}}</th>
                    <th colspan="2" class="width6">Difference</th>
                    <th rowspan="2" class="width1">
                        <i class="fas fa-minus clickable remove-icon" pTooltip="Remove All Transactions from Compare" (click)="removeAllFromCompare()"></i>
                    </th>
                </tr>
                <tr>
                    <th class="right">Current Value</th>
                    <th class="right">$ Performance</th>
                    <th class="right">% Performance</th>
                    <th class="right border-left">Current Value</th>
                    <th class="right">$ Performance</th>
                    <th class="right">% Performance</th>
                    <th class="right border-left">$</th>
                    <th class="right">%</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-transaction let-rowIndex="rowIndex">
                <tr>
                    <td>
                        <span class="p-column-title">Date</span>
                        {{transaction.date | date:'shortDate'}}
                    </td>
                    <td class="left">
                        <span class="p-column-title">Holding</span>
                        {{transaction.accountHolding.security.displayText}}
                    </td>
                    <td class="right">
                        <span class="p-column-title">Shares</span>
                        {{transaction.shares | number:'1.4-4'}}
                    </td>
                    <td class="right">
                        <span class="p-column-title">Price</span>
                        {{transaction.price | currency}}
                    </td>
                    <td class="right">
                        <span class="p-column-title">Fees</span>
                        {{transaction.fees | currency}}
                    </td>
                    <td class="right">
                        <span class="p-column-title">Total</span>
                        {{transaction.total | currency}}
                    </td>
                    <td class="right">
                        <span class="p-column-title">Transaction Current Value</span>
                        {{transaction.performance?.endingBalance | currency}}
                    </td>
                    <td class="right" [ngClass]="getFinancialReturnCssClass(transaction.performance?.amount)">
                        <span class="p-column-title">Transaction $ Performance</span>
                        {{transaction.performance?.amount | currency}}
                    </td>
                    <td class="right" [ngClass]="getFinancialReturnCssClass(transaction.performance?.percent)">
                        <span class="p-column-title">Transaction % Performance</span>
                        {{transaction.performance?.percent | percent:'1.2-2'}}
                    </td>
                    <td class="right">
                        <span class="p-column-title">{{securityTicker}} Current Value</span>
                        {{transaction.performanceAlternative?.endingBalance | currency}}
                    </td>
                    <td class="right" [ngClass]="getFinancialReturnCssClass(transaction.performanceAlternative?.amount)">
                        <span class="p-column-title">{{securityTicker}} $ Performance</span>
                        {{transaction.performanceAlternative?.amount | currency}}
                    </td>
                    <td class="right" [ngClass]="getFinancialReturnCssClass(transaction.performanceAlternative?.percent)">
                        <span class="p-column-title">{{securityTicker}} % Performance</span>
                        {{transaction.performanceAlternative?.percent | percent:'1.2-2'}}
                    </td>
                    <td class="right" [ngClass]="getFinancialReturnCssClass(transaction.performanceDifference?.amount)">
                        <span class="p-column-title">$ Difference</span>
                        {{transaction.performanceDifference?.amount | currency}}
                    </td>
                    <td class="right" [ngClass]="getFinancialReturnCssClass(transaction.performanceDifference?.percent)">
                        <span class="p-column-title">% Difference</span>
                        {{transaction.performanceDifference?.percent | percent:'1.2-2'}}
                    </td>
                    <td>
                        <i class="fas fa-minus clickable remove-icon" pTooltip="Remove from Compare" (click)="removeFromCompare(transaction)"></i>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                    <td colspan="5"></td>
                    <td class="right">{{transactionTotal | currency}}</td>
                    <td class="right">{{transactionCurrentValueTotal | currency}}</td>
                    <td class="right" [ngClass]="getFinancialReturnCssClass(transactionPerformanceAmount)">
                        {{transactionPerformanceAmount | currency}}
                    </td>
                    <td class="right" [ngClass]="getFinancialReturnCssClass(transactionPerformancePercent)">
                        {{transactionPerformancePercent | percent:'1.2-2'}}
                    </td>
                    <td class="right">{{alternativeCurrentValueTotal | currency}}</td>
                    <td class="right" [ngClass]="getFinancialReturnCssClass(alternativePerformanceAmount)">
                        {{alternativePerformanceAmount | currency}}
                    </td>
                    <td class="right" [ngClass]="getFinancialReturnCssClass(alternativePerformancePercent)">
                        {{alternativePerformancePercent | percent:'1.2-2'}}
                    </td>
                    <td class="right" [ngClass]="getFinancialReturnCssClass(differenceAmount)">
                        {{differenceAmount | currency}}
                    </td>
                    <td class="right" [ngClass]="getFinancialReturnCssClass(differencePercent)">
                        {{differencePercent | percent:'1.2-2'}}
                    </td>
                    <td></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="15">
                        No transactions to compare.
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
