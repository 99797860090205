import { Injectable } from '@angular/core';
import { StorageService } from '../../shared/core/storage.service';
import * as models from '../../shared/swagger-codegen/models';

@Injectable()
export class SecurityCompareService {
    constructor(private storage: StorageService) {
        this.securities = this.readSecurities() || [];
        this.securityIds = new Set(this.securities.map(t => t.securityId));
    }

    private compareSecuritiesKey = 'security-compare';
    private securities: models.Security[];
    private securityIds: Set<number>;

    get securityCount() {
        return this.securities.length;
    }

    refreshSecurities(securities: models.Security[]) {
        this.securities = securities
            .filter(security => this.containsSecurity(security.securityId));
        this.securitiesUpdated();
    }

    containsSecurity(securityId: number) {
        return this.securityIds.has(securityId);
    }

    addSecurity(security: models.Security) {
        if(!this.containsSecurity(security.securityId)) {
            this.securityIds.add(security.securityId);
            this.securities.push(security);
            this.securitiesUpdated();
        }
    }

    removeSecurity(securityId: number) {
        if(this.containsSecurity(securityId)) {
            const i = this.securities.findIndex(t => t.securityId === securityId);

            if(i !== -1) {
                this.securityIds.delete(securityId);
                this.securities.splice(i, 1);
                this.securitiesUpdated();
            }
        }
    }

    getSecurities() {
        return this.securities;
    }

    clearSecurities() {
        this.securities.length = 0;
        this.securityIds.clear();
        this.deleteSecurities();
    }

    private securitiesUpdated() {
        this.sortSecurities();
        this.writeSecurities();
    }

    private sortSecurities() {
        this.securities.sort((a,b) => {
            return a.displayText < b.displayText ? -1 : 1;
        });
    }

    private readSecurities() {
        return this.storage.getSessionItem(this.compareSecuritiesKey) as models.Security[];
    }

    private writeSecurities() {
        this.storage.setSessionItem(this.compareSecuritiesKey, this.securities);
    }

    private deleteSecurities() {
        this.storage.deleteSessionItem(this.compareSecuritiesKey);
    }
}
