<div class="grid">
    <form>
        <div class="formgroup-inline">
            <div class="field">
                <input id="filter" name="filter" [(ngModel)]="filter" pInputText placeholder="Filter..." class="width10">
            </div>
            <div class="field">
                <button pButton type="button" label="Apply Filter" (click)="applyFilter()"></button>
            </div>
            <div class="field" *ngIf="isFiltered">
                <button pButton type="button" label="Clear Filter" (click)="clearFilter()"></button>
            </div>
        </div>
    </form>
</div>
<p-table [value]="filteredStockExposures" [loading]="isBusy" [paginator]="filteredStockExposures.length > 100" [pageLinks]="10" [rows]="100" styleClass="p-datatable-striped">
    <ng-template pTemplate="header">
        <tr valign="bottom">
            <th rowspan="2" class="left">Stock</th>
            <th rowspan="2" class="width5">Payoff<br />Profile</th>
            <th colspan="2" class="width10 center">Current</th>
            <th colspan="2" class="width10 center">Rebalanced</th>
        </tr>
        <tr valign="bottom">
            <th class="width5 right">Percent</th>
            <th class="width5 right">Amount</th>
            <th class="width5 right">Percent</th>
            <th class="width5 right">Amount</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-exposure>
        <tr>
            <td class="left">
                <span class="p-column-title">Stock</span>
                {{exposure.name}}
            </td>
            <td class="center">
                <span class="p-column-title">Payoff Profile</span>
                {{exposure.payoffProfile}}
            </td>
            <td class="right">
                <span class="p-column-title">Current Percent</span>
                {{exposure.currentPercent | percent:getExposurePercentFormat(exposure.currentPercent)}}
            </td>
            <td class="right">
                <span class="p-column-title">Current Amount</span>
                {{exposure.currentAmount | currency:undefined:'symbol':getExposureCurrencyFormat(exposure.currentAmount)}}
            </td>
            <td class="right">
                <span class="p-column-title">Rebalanced Percent</span>
                {{exposure.rebalancedPercent | percent:getExposurePercentFormat(exposure.rebalancedPercent)}}
            </td>
            <td class="right">
                <span class="p-column-title">Rebalanced Amount</span>
                {{exposure.rebalancedAmount | currency:undefined:'symbol':getExposureCurrencyFormat(exposure.rebalancedAmount)}}
            </td>
        </tr>
    </ng-template>
</p-table>
