import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesModule } from './messages/messages.module';
import { PrimeNgModule } from './primeng/primeng.module';
import { ChartService } from './chart.service';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
} from 'chart.js';
import 'chartjs-adapter-date-fns';

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
);

@NgModule(
    {
        declarations: [
        ],
        providers: [
            ChartService
        ],
        imports: [
            CommonModule,
            MessagesModule,
            PrimeNgModule
        ],
        exports: [
            MessagesModule,
            PrimeNgModule
        ]
    })
export class UIModule {
}
