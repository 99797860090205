import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StorageService } from './storage.service';
import { ConfigurationService } from './configuration.service';

@NgModule(
    {
        declarations: [
        ],
        providers: [
            StorageService,
            ConfigurationService
        ],
        imports: [
            CommonModule
        ],
        exports: [
        ]
    })
export class CoreModule {
}
