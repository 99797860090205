import { Component, Input, OnInit } from '@angular/core';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { StockStyles } from './stock-styles-nine-box.component';
import * as models from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-stock-styles',
    templateUrl: './stock-styles.component.html'
})
export class StockStylesComponent extends BaseComponentDirective implements OnInit {
    constructor() {
        super();
    }

    @Input()
    set accounts(accounts: models.AccountAndHoldings[]) {
        this.myAccounts = accounts;
        this.loadStockStyles();
    }

    get accounts(): models.AccountAndHoldings[] {
        return this.myAccounts;
    }

    @Input()
    set securityHoldings(securityHoldings: { [securityId: number]: models.SecurityHolding[] }) {
        this.mySecurityHoldings = securityHoldings;
        this.loadStockStyles();
    }

    get securityHoldings() {
        return this.mySecurityHoldings;
    }

    @Input()
    set benchmarks(benchmarks: models.Security[]) {
        this.myBenchmarks = benchmarks;
        this.loadBenchmarkStockStyles();
    }

    get benchmarks(): models.Security[] {
        return this.myBenchmarks;
    }

    get hasBenchmarks() {
        return this.myBenchmarks && this.myBenchmarks.length > 0;
    }

    stockStyleAmounts: StockStyles;
    stockStylePercentages: StockStyles;
    benchmarkStockStylePercentages: StockStyles;
    isBusy = false;
    private myAccounts: models.AccountAndHoldings[] = [];
    private mySecurityHoldings: { [securityId: number]: models.SecurityHolding[] } = {};
    private myBenchmarks: models.Security[] = [];

    ngOnInit() {
    }

    private loadStockStyles() {
        let total = 0;
        this.stockStyleAmounts = {
            largeValue: 0,
            largeBlend: 0,
            largeGrowth: 0,
            mediumValue: 0,
            mediumBlend: 0,
            mediumGrowth: 0,
            smallValue: 0,
            smallBlend: 0,
            smallGrowth: 0
        };

        this.myAccounts.forEach(account => {
            total += account.balance;

            account.holdings.forEach(holding => {
                const portfolio = holding.security.portfolio;

                if(portfolio) {
                    this.stockStyleAmounts.largeValue += (portfolio.stockStyleLargeValue || 0) * holding.balance;
                    this.stockStyleAmounts.largeBlend += (portfolio.stockStyleLargeBlend || 0) * holding.balance;
                    this.stockStyleAmounts.largeGrowth += (portfolio.stockStyleLargeGrowth || 0) * holding.balance;
                    this.stockStyleAmounts.mediumValue += (portfolio.stockStyleMediumValue || 0) * holding.balance;
                    this.stockStyleAmounts.mediumBlend += (portfolio.stockStyleMediumBlend || 0) * holding.balance;
                    this.stockStyleAmounts.mediumGrowth += (portfolio.stockStyleMediumGrowth || 0) * holding.balance;
                    this.stockStyleAmounts.smallValue += (portfolio.stockStyleSmallValue || 0) * holding.balance;
                    this.stockStyleAmounts.smallBlend += (portfolio.stockStyleSmallBlend || 0) * holding.balance;
                    this.stockStyleAmounts.smallGrowth += (portfolio.stockStyleSmallGrowth || 0) * holding.balance;
                }
            });
        });

        this.stockStylePercentages = {
            largeValue: this.stockStyleAmounts.largeValue / total,
            largeBlend: this.stockStyleAmounts.largeBlend / total,
            largeGrowth: this.stockStyleAmounts.largeGrowth / total,
            mediumValue: this.stockStyleAmounts.mediumValue / total,
            mediumBlend: this.stockStyleAmounts.mediumBlend / total,
            mediumGrowth: this.stockStyleAmounts.mediumGrowth / total,
            smallValue: this.stockStyleAmounts.smallValue / total,
            smallBlend: this.stockStyleAmounts.smallBlend / total,
            smallGrowth: this.stockStyleAmounts.smallGrowth / total
        };
    }

    private loadBenchmarkStockStyles() {
        this.benchmarkStockStylePercentages = {
            largeValue: 0,
            largeBlend: 0,
            largeGrowth: 0,
            mediumValue: 0,
            mediumBlend: 0,
            mediumGrowth: 0,
            smallValue: 0,
            smallBlend: 0,
            smallGrowth: 0
        };

        if(this.hasBenchmarks) {
            const portfolio = this.benchmarks[0].portfolio;

            if(portfolio) {
                this.benchmarkStockStylePercentages.largeValue = portfolio.stockStyleLargeValue || 0;
                this.benchmarkStockStylePercentages.largeBlend = portfolio.stockStyleLargeBlend || 0;
                this.benchmarkStockStylePercentages.largeGrowth = portfolio.stockStyleLargeGrowth || 0;
                this.benchmarkStockStylePercentages.mediumValue = portfolio.stockStyleMediumValue || 0;
                this.benchmarkStockStylePercentages.mediumBlend = portfolio.stockStyleMediumBlend || 0;
                this.benchmarkStockStylePercentages.mediumGrowth = portfolio.stockStyleMediumGrowth || 0;
                this.benchmarkStockStylePercentages.smallValue = portfolio.stockStyleSmallValue || 0;
                this.benchmarkStockStylePercentages.smallBlend = portfolio.stockStyleSmallBlend || 0;
                this.benchmarkStockStylePercentages.smallGrowth = portfolio.stockStyleSmallGrowth || 0;
            }
        }
    }
}
