import { NgModule } from '@angular/core';
import { AccountsProxy } from './accounts-proxy.service';
import { AuthenticationProxy } from './authentication-proxy.service';
import { HttpClientWrapperService } from './http-client-wrapper.service';
import { InstitutionsProxy } from './institutions-proxy.service';
import { SecuritiesProxy } from './securities-proxy.service';
import { UsersProxy } from './users-proxy.service';

@NgModule(
    {
        providers: [
            AccountsProxy,
            AuthenticationProxy,
            HttpClientWrapperService,
            InstitutionsProxy,
            SecuritiesProxy,
            UsersProxy
        ]
    })
export class ServerProxiesModule {
}
