<p-table [value]="allocations" styleClass="p-datatable-striped">
    <ng-template pTemplate="header">
        <tr valign="bottom">
            <th class="left">Asset</th>
            <th class="width5 right">Current</th>
            <th class="width5 right">Target</th>
            <th class="width5 right">Rebalance</th>
            <th class="width3 right" *ngIf="showBenchmark">VTSAX</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-allocation>
        <tr>
            <td class="left">
                <span class="p-column-title">Name</span>
                {{allocation.name}}
            </td>
            <td class="right" valign="top">
                <span class="p-column-title">Current</span>
                {{allocation.currentBalance | currency}}<br />
                <span pTooltip="% of Total Balance">{{allocation.currentAllocation | percent:'1.2-2'}}</span>
                <span *ngIf="allocation.currentAllocationOfAssetClass" [pTooltip]="getPercentOfAssetClassTooltip(allocation)">
                    / {{allocation.currentAllocationOfAssetClass | percent:'1.2-2'}}
                </span>
                <span [ngClass]="getAllocationDifferenceCssClass(allocation.currentDifference)" [pTooltip]="getCurrentDifferenceTooltip(allocation)">
                    <br />{{allocation.currentDifference | percent:'1.2-2'}}
                </span>
            </td>
            <td class="right" valign="top">
                <span class="p-column-title">Target</span>
                {{allocation.targetBalance | currency}}<br />
                <span pTooltip="% of Total Balance">{{allocation.targetAllocation | percent:'1.2-2'}}</span>
                <span *ngIf="allocation.targetAllocationOfAssetClass" [pTooltip]="getPercentOfAssetClassTooltip(allocation)">
                    / {{allocation.targetAllocationOfAssetClass | percent:'1.2-2'}}
                </span>
            </td>
            <td class="right" valign="top">
                <span class="p-column-title">Rebalance</span>
                {{allocation.rebalanceBalance | currency}}<br />
                <span pTooltip="% of Total Balance">{{allocation.rebalanceAllocation | percent:'1.2-2'}}</span>
                <span *ngIf="allocation.rebalanceAllocationOfAssetClass" [pTooltip]="getPercentOfAssetClassTooltip(allocation)">
                    / {{allocation.rebalanceAllocationOfAssetClass | percent:'1.2-2'}}
                </span>
                <span [ngClass]="getAllocationDifferenceCssClass(allocation.rebalanceDifference)" [pTooltip]="getRebalanceDifferenceTooltip(allocation)">
                    <br />{{allocation.rebalanceDifference | percent:'1.2-2'}}
                </span>
            </td>
            <td class="right" *ngIf="showBenchmark">
                <span class="p-column-title">VTSAX</span>
                {{allocation.benchmarkAllocation | percent:'1.2-2'}}
                <span [ngClass]="getAllocationDifferenceCssClass(allocation.benchmarkDifference)" [pTooltip]="getBenchmarkDifferenceTooltip(allocation)">
                    <br />{{allocation.benchmarkDifference | percent:'1.2-2'}}
                </span>
            </td>
        </tr>
    </ng-template>
</p-table>
