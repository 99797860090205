import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { usersRoutes } from './users.routes';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
        usersRoutes,
        CommonModule,
        FormsModule,
        SharedModule
    ],
    declarations: [
    ],
    providers: [
    ]
})
export class UsersModule {

}
