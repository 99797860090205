import { Component, Input, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import {
    AnalyticsBaseDirective,
    AllocationGroupConfig
} from '../analytics-base.directive';
import { AccountsProxy, SecuritiesProxy } from '../../../shared/server-proxies';
import * as models from '../../../shared/swagger-codegen/models';

/* eslint-disable no-shadow */
interface RebalanceInstructions {
    name: string;
    action: string;
    amount: number;
    percent: number;
}

export interface AccountHoldingWithAllocations {
    accountHoldingId: number;
    name: string;
    currentBalance: number;
    targetBalance: number;
    rebalanceBalance: number;
    currentAllocation: number;
    targetAllocation: number;
    rebalanceAllocation: number;
    security: models.Security;
}

export interface AccountWithHoldingAllocations {
    accountId: number;
    name: string;
    holdings: AccountHoldingWithAllocations[];
    rebalanceInstructions: RebalanceInstructions[];
    balance: number;
    currentAllocationTotal: number;
    targetAllocationTotal: number;
    rebalanceAllocationTotal: number;
}

export interface AccountAllocation {
    name: string;
    currentBalance: number;
    targetBalance: number;
    rebalanceBalance: number;
    currentAllocation: number;
    targetAllocation: number;
    rebalanceAllocation: number;
    benchmarkAllocation: number;
    currentDifference: number;
    rebalanceDifference: number;
    benchmarkDifference: number;
    currentAllocationOfAssetClass?: number;
    targetAllocationOfAssetClass?: number;
    rebalanceAllocationOfAssetClass?: number;
    isEquity?: boolean;
    isBond?: boolean;
}

@Component({
    selector: 'my-account-rebalance',
    templateUrl: './account-rebalance.component.html'
})
export class AccountRebalanceComponent extends AnalyticsBaseDirective implements OnInit {
    constructor(
        private accountsProxy: AccountsProxy,
        private securitiesProxy: SecuritiesProxy,
        private decimalPipe: DecimalPipe) {
        super();
        this.loadAllocationGroupConfigs();
    }

    @Input()
    set accounts(accounts: models.AccountAndHoldings[]) {
        this.myAccounts = accounts;
        this.setAccounts();
        this.updateAllocations();
    }

    get accounts() {
        return this.myAccounts;
    }

    @Input()
    securityHoldings: { [securityId: number]: models.SecurityHolding[] } = {};
    accountsWithAllocations: AccountWithHoldingAllocations[] = [];
    rebalancedAccounts: AccountWithHoldingAllocations[] = [];
    assetAllocations: AccountAllocation[] = [];
    marketCapAllocations: AccountAllocation[] = [];
    regionAllocations: AccountAllocation[] = [];
    sectorAllocations: AccountAllocation[] = [];
    holdingAllocations: AccountAllocation[] = [];
    securities: models.Security[] = [];
    benchmarks: models.Security[] = [];
    showZeroBalanceHoldings = false;
    private myAccounts: models.AccountAndHoldings[] = [];
    private accountMap: { [accountId: number]: AccountWithHoldingAllocations } = {};

    ngOnInit() {
        this.loadSecurities();
    }

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected loadGroupNamesAndAmounts(holding: models.AccountHolding, groupsAndAmounts: [string, number][]) {
    }

    showZeroBalanceHoldingsChanged() {
        this.setAccounts(true);
        this.updateAllocations();
    }

    setAllRebalanceAllocationsToCurrent(account: AccountWithHoldingAllocations) {
        account.holdings.forEach(holding => {
            holding.rebalanceAllocation = this.percentToRebalanceAllocation(holding.currentAllocation);
            holding.rebalanceBalance = holding.currentBalance;
        });

        account.rebalanceAllocationTotal = account.currentAllocationTotal;
        this.updateAllocations();
    }

    setAllRebalanceAllocationsToTarget(account: AccountWithHoldingAllocations) {
        account.holdings.forEach(holding => {
            holding.rebalanceAllocation = this.percentToRebalanceAllocation(holding.targetAllocation);
            holding.rebalanceBalance = holding.targetBalance;
        });

        account.rebalanceAllocationTotal = account.targetAllocationTotal;
        this.updateAllocations();
    }

    setRebalanceAllocation(account: AccountWithHoldingAllocations, holding: AccountHoldingWithAllocations, allocation: number) {
        holding.rebalanceAllocation = this.percentToRebalanceAllocation(allocation);
        this.onRebalanceAllocationChanged(account, holding);
    }

    onRebalanceAllocationChanged(account: AccountWithHoldingAllocations, holding: AccountHoldingWithAllocations) {
        const totalRebalanceAllocation = this.sumHoldings(account, h => h.rebalanceAllocation);
        account.rebalanceAllocationTotal = totalRebalanceAllocation / 100.0;

        if(holding) {
            holding.rebalanceBalance = account.balance * (holding.rebalanceAllocation / 100.0);
        }

        this.updateAllocations();
    }

    getTotalRebalanceAllocationCssClass(account: AccountWithHoldingAllocations) {
        const value = Math.round(account.rebalanceAllocationTotal);

        /* eslint-disable @typescript-eslint/naming-convention */
        return {
            'positive-return': value === 1,
            'negative-return': value !== 1
        };
        /* eslint-enable @typescript-eslint/naming-convention */
    }

    addHolding(account: AccountWithHoldingAllocations, security: models.Security) {
        const index = account.holdings.findIndex(holding => holding.security.securityId === security.securityId);

        if(index < 0) {
            account.holdings.push({
                accountHoldingId: 0,
                currentAllocation: 0,
                currentBalance: 0,
                name: security.displayText,
                rebalanceAllocation: 0,
                rebalanceBalance: 0,
                targetAllocation: 0,
                targetBalance: 0,
                security: security
            });
        }
    }

    removeHolding(account: AccountWithHoldingAllocations, holdingIndex: number) {
        if(holdingIndex < account.holdings.length) {
            const holding = account.holdings[holdingIndex];
            holding.rebalanceAllocation = 0;
            this.onRebalanceAllocationChanged(account, holding);
        }
    }

    saveTargetAllocations() {
        const allocations: models.AccountHoldingTargetAllocation[] = [];

        this.accountsWithAllocations.forEach(account => {
            account.holdings.forEach(holding => {
                if(holding.accountHoldingId > 0 && holding.rebalanceAllocation >= 0) {
                    allocations.push({
                        accountHoldingId: holding.accountHoldingId,
                        targetAllocation: holding.rebalanceAllocation / 100
                    });
                }
            });
        });

        if(allocations.length > 0) {
            this.accountsProxy.saveTargetAllocations(allocations)
                .subscribe();
        }
    }

    private loadSecurities() {
        this.securitiesProxy.getSecurities()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    const marketIndexTypeId = 6;
                    this.securities = response.body
                        .filter(s => s.securityType.securityTypeId !== marketIndexTypeId)
                        .sort((a,b) => a.displayText < b.displayText ? -1 : 1);
                    this.updateAllocations();
                });
    }

    private setAccounts(forceReload = false) {
        this.accountsWithAllocations = this.accounts
            .filter(account => !account.closeDate)
            .map(account => {
                let a = this.accountMap[account.accountId];

                if(!a || forceReload) {
                    a = this.createAccountWithHoldingAllocations(account);
                    this.accountMap[account.accountId] = a;

                    a.holdings.forEach(h => {
                        h.currentAllocation = h.currentBalance / a.balance;
                        h.targetBalance = a.balance * h.targetAllocation;
                        h.rebalanceBalance = h.targetBalance;
                        h.rebalanceAllocation = this.percentToRebalanceAllocation(h.targetAllocation);
                    });

                    a.currentAllocationTotal = this.sumHoldings(a, h => h.currentAllocation);
                    a.targetAllocationTotal = this.sumHoldings(a, h => h.targetAllocation);
                    a.rebalanceAllocationTotal = this.sumHoldings(a, h => h.rebalanceAllocation) / 100.0;
                }

                return a;
            });
    }

    private percentToRebalanceAllocation(percent: number) {
        const target = this.decimalPipe.transform(percent * 100.0, '1.0-2');
        return parseFloat(target);
    }

    private createAccountWithHoldingAllocations(a: models.AccountAndHoldings) {
        const accountWithHoldingAllocations: AccountWithHoldingAllocations = {
            accountId: a.accountId,
            name: `${a.institution.name}: ${a.name}`,
            holdings: [],
            rebalanceInstructions: [],
            balance: 0.0,
            currentAllocationTotal: 0.0,
            targetAllocationTotal: 0.0,
            rebalanceAllocationTotal: 0.0
        };

        accountWithHoldingAllocations.holdings = a.holdings
            .filter(h => h.balance > 0 || h.targetAllocation > 0 || this.showZeroBalanceHoldings)
            .map(h => {
                accountWithHoldingAllocations.balance += h.balance;

                const holding: AccountHoldingWithAllocations =  {
                    accountHoldingId: h.accountHoldingId,
                    name: h.security.displayText,
                    currentBalance: h.balance,
                    targetBalance: 0.0,
                    rebalanceBalance: 0.0,
                    currentAllocation: 0.0,
                    targetAllocation: h.targetAllocation,
                    rebalanceAllocation: 0.0,
                    security: h.security
                };

                return holding;
            });

        return accountWithHoldingAllocations;
    }

    private sumHoldings(
        account: AccountWithHoldingAllocations,
        getAllocation: (AccountHoldingWithAllocations) => number) {

        return account.holdings
            .filter(h => !isNaN(getAllocation(h)))
            .reduce((total, holding) => total + getAllocation(holding), 0);
    }

    private updateAllocations() {
        const vtsax = this.securities.find(security => security.securityId === 1);

        const assetClassAllocations = this.initializeAllocations(this.assetAllocationGroupConfigs, vtsax);
        const marketCapAllocations = this.initializeAllocations(this.marketCapAllocationGroupConfigs, vtsax);
        const regionAllocations = this.initializeAllocations(this.regionAllocationGroupConfigs, vtsax);
        const sectorAllocations = this.initializeAllocations(this.sectorAllocationGroupConfigs, vtsax);

        const holdingAllocations: { [securityId: string]: AccountAllocation } = {};

        this.accountsWithAllocations.forEach(account => {
            account.holdings.forEach(holding => {
                this.addBalances(this.assetAllocationGroupConfigs, assetClassAllocations, holding);
                this.addBalances(this.marketCapAllocationGroupConfigs, marketCapAllocations, holding);
                this.addBalances(this.regionAllocationGroupConfigs, regionAllocations, holding);
                this.addBalances(this.sectorAllocationGroupConfigs, sectorAllocations, holding);
                this.addHoldingAllocations(holdingAllocations, holding);
            });
        }, vtsax);

        this.setPercentageOfAssetClassForAllocations(this.assetAllocationGroupConfigs, assetClassAllocations);

        this.assetAllocations = this.convertAllocationsToArray(assetClassAllocations);
        this.marketCapAllocations = this.convertAllocationsToArray(marketCapAllocations);
        this.regionAllocations = this.convertAllocationsToArray(regionAllocations);
        this.sectorAllocations = this.convertAllocationsToArray(sectorAllocations);
        this.holdingAllocations = this.convertAllocationsToArray(holdingAllocations);

        this.updateRebalanceInstructions();

        if(vtsax) {
            this.benchmarks = [vtsax];
        }

        // Change the instance of accountsWithAllocations so the stock exposure component will update.
        this.accountsWithAllocations = this.accountsWithAllocations.map(a => a);
    }

    private initializeAllocations(groups: AllocationGroupConfig[], benchmark: models.Security) {
        const allocations: { [key: string]: AccountAllocation } = {};

        groups.forEach(group => {
            allocations[group.name] = {
                name: group.name,
                currentBalance: 0.0,
                targetBalance: 0.0,
                rebalanceBalance: 0.0,
                currentAllocation: 0.0,
                targetAllocation: 0.0,
                rebalanceAllocation: 0.0,
                benchmarkAllocation: benchmark && benchmark.portfolio ? group.percentageAccessor(benchmark) || 0 : 0,
                currentDifference: 0.0,
                rebalanceDifference: 0.0,
                benchmarkDifference: 0.0,
                isEquity: group.isEquity,
                isBond: group.isBond
            };
        });

        return allocations;
    }

    private addBalances(
        groups: AllocationGroupConfig[],
        nameAllocationMap: { [p: string]: AccountAllocation },
        holding: AccountHoldingWithAllocations) {

        groups.forEach(group => {
            const allocation = nameAllocationMap[group.name];
            const portfolioAllocation = group.percentageAccessor(holding.security);

            allocation.currentBalance += holding.currentBalance * portfolioAllocation;
            allocation.targetBalance += holding.targetBalance * portfolioAllocation;
            allocation.rebalanceBalance += holding.rebalanceBalance * portfolioAllocation;
        });
    }

    private addHoldingAllocations(
        holdingAllocations: { [securityId: string]: AccountAllocation },
        holding: AccountHoldingWithAllocations) {

        let allocation = holdingAllocations[holding.security.securityId.toString()];

        if(!allocation) {
            allocation = {
                name: holding.security.displayText,
                currentBalance: 0,
                targetBalance: 0,
                rebalanceBalance: 0,
                currentAllocation: 0,
                targetAllocation: 0,
                rebalanceAllocation: 0,
                benchmarkAllocation: 0,
                currentDifference: 0,
                rebalanceDifference: 0,
                benchmarkDifference: 0
            };

            holdingAllocations[holding.security.securityId.toString()] = allocation;
        }

        allocation.currentBalance += holding.currentBalance;
        allocation.targetBalance += holding.targetBalance;
        allocation.rebalanceBalance += holding.rebalanceBalance;
    }

    private setPercentageOfAssetClassForAllocations(
        groups: AllocationGroupConfig[],
        nameAllocationMap: { [p: string]: AccountAllocation }) {

        const equityAllocations: AccountAllocation[] = [];
        const bondAllocations: AccountAllocation[] = [];
        let totalEquityCurrent = 0;
        let totalEquityTarget = 0;
        let totalEquityRebalance = 0;
        let totalBondCurrent = 0;
        let totalBondTarget = 0;
        let totalBondRebalance = 0;

        groups.forEach(group => {
            const allocation = nameAllocationMap[group.name];

            if(allocation) {
                if(group.isEquity) {
                    equityAllocations.push(allocation);
                    totalEquityCurrent += allocation.currentBalance;
                    totalEquityTarget += allocation.targetBalance;
                    totalEquityRebalance += allocation.rebalanceBalance;
                }
                else if(group.isBond) {
                    bondAllocations.push(allocation);
                    totalBondCurrent += allocation.currentBalance;
                    totalBondTarget += allocation.targetBalance;
                    totalBondRebalance += allocation.rebalanceBalance;
                }
            }
        });

        this.setPercentageOfAssetClass(
            totalEquityCurrent,
            totalEquityTarget,
            totalEquityRebalance,
            equityAllocations);
        this.setPercentageOfAssetClass(
            totalBondCurrent,
            totalBondTarget,
            totalBondRebalance,
            bondAllocations);
    }

    private setPercentageOfAssetClass(
        currentTotal: number,
        targetTotal: number,
        rebalanceTotal: number,
        allocations: AccountAllocation[]) {

        allocations.forEach(allocation => {
            allocation.currentAllocationOfAssetClass = currentTotal > 0
                ? allocation.currentBalance / currentTotal
                : undefined;
            allocation.targetAllocationOfAssetClass = targetTotal > 0
                ? allocation.targetBalance / targetTotal
                : undefined;
            allocation.rebalanceAllocationOfAssetClass = rebalanceTotal > 0
                ? allocation.rebalanceBalance / rebalanceTotal
                : undefined;
        });
    }

    private convertAllocationsToArray(allocations: { [key: string]: AccountAllocation }) {
        const array = Object.keys(allocations)
            .map(propertyName => {
                return allocations[propertyName];
            })
            .sort((a,b) => {
                if(a.rebalanceBalance === b.rebalanceBalance) {
                    return a.name < b.name ? -1 : 1;
                }
                else {
                    return a.rebalanceBalance < b.rebalanceBalance ? 1 : -1;
                }
            });

        const totalBalance = this.accountsWithAllocations.reduce((total, a) => total + a.balance, 0);

        array.forEach(allocation => {
            allocation.currentAllocation = allocation.currentBalance / totalBalance;
            allocation.targetAllocation = allocation.targetBalance / totalBalance;
            allocation.rebalanceAllocation = allocation.rebalanceBalance / totalBalance;

            allocation.currentDifference = allocation.currentAllocation - allocation.targetAllocation;
            allocation.rebalanceDifference = allocation.rebalanceAllocation - allocation.targetAllocation;
            allocation.benchmarkDifference = allocation.benchmarkAllocation - allocation.targetAllocation;
        });

        return array
            .filter(h =>
                h.currentBalance > 0
                || h.targetBalance > 0
                || h.rebalanceBalance > 0
                || this.showZeroBalanceHoldings
            );
    }

    private updateRebalanceInstructions() {
        this.accountsWithAllocations.forEach(account => {
            const changed = account.holdings.filter(h => h.currentBalance !== h.rebalanceBalance);
            account.rebalanceInstructions = changed.map(c => {
                const amount = Math.abs(c.rebalanceBalance - c.currentBalance);

                return {
                    name: c.security.displayText,
                    action: c.rebalanceBalance > c.currentBalance ? 'Buy' : 'Sell',
                    amount: amount,
                    percent: c.rebalanceBalance > c.currentBalance ? undefined : amount / c.currentBalance,
                };
            });
        });

        this.rebalancedAccounts = this.accountsWithAllocations.filter(a => a.rebalanceInstructions.length > 0);
    }
}
