import { Component, Input, OnInit } from '@angular/core';
import { Allocation, AllocationGroup, AllocationGroupConfig, AnalyticsBaseDirective } from '../analytics-base.directive';
import * as models from '../../../shared/swagger-codegen/models';


@Component({
    selector: 'my-asset-allocation',
    templateUrl: './asset-allocation.component.html'
})
export class AssetAllocationComponent extends AnalyticsBaseDirective implements OnInit {
    constructor() {
        super();

        this.loadAllocationGroupConfigs();

        this.assetAllocationGroupConfigs.forEach(group => {
            this.groupPercentageAccessors[group.name] = group.percentageAccessor;
        });
    }

    @Input()
    set accounts(accounts: models.AccountAndHoldings[]) {
        this.myAccounts = accounts;
        this.loadAllocations(accounts, this.benchmarks);
    }

    get accounts(): models.AccountAndHoldings[] {
        return this.myAccounts;
    }

    @Input()
    set benchmarks(benchmarks: models.Security[]) {
        this.myBenchmarks = benchmarks;
        this.loadAllocations(this.accounts, benchmarks);
    }

    get benchmarks(): models.Security[] {
        return this.myBenchmarks;
    }
    private myBenchmarks: models.Security[] = [];
    private myAccounts: models.AccountAndHoldings[] = [];

    ngOnInit() {
    }

    protected loadGroupNamesAndAmounts(holding: models.AccountHolding, groupNamesAndAmounts: [string, number][]) {
        this.loadGroupNamesAndAmountsUsingPercentageAccessors(holding, groupNamesAndAmounts);
    }

    protected prepareFinalAllocation(allocation: Allocation) {
        super.prepareFinalAllocation(allocation);
        this.setPercentageOfAssetClassForAllocations(allocation);
    }

    private setPercentageOfAssetClassForAllocations(allocation: Allocation) {
        const groupMap: { [name: string]: AllocationGroupConfig } = {};

        this.assetAllocationGroupConfigs.forEach(group => {
            groupMap[group.name] = group;
        });

        const equityAllocationGroups: AllocationGroup[] = [];
        const bondAllocationGroups: AllocationGroup[] = [];
        let totalEquity = 0;
        let totalBonds = 0;

        allocation.groups.forEach(allocationGroup => {
            const group = groupMap[allocationGroup.name];

            if(group) {
                if(group.isEquity) {
                    equityAllocationGroups.push(allocationGroup);
                    totalEquity += allocationGroup.amount;
                }
                else if(group.isBond) {
                    bondAllocationGroups.push(allocationGroup);
                    totalBonds += allocationGroup.amount;
                }

                allocationGroup.isEquity = group.isEquity;
                allocationGroup.isBond = group.isBond;
            }
        });

        this.setPercentageOfAssetClass(totalEquity, equityAllocationGroups);
        this.setPercentageOfAssetClass(totalBonds, bondAllocationGroups);
    }

    private setPercentageOfAssetClass(total: number, allocationGroups: AllocationGroup[]) {
        if(total > 0) {
            allocationGroups.forEach(allocationGroup => {
                allocationGroup.percentageOfAssetClass = allocationGroup.amount / total;
            });
        }
    }
}
