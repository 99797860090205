import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Observable, forkJoin } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import * as models from '../../shared/swagger-codegen/models';
import { AccountsProxy, SecuritiesProxy, UsersProxy } from '../../shared/server-proxies';
import { CurrentUserService } from '../../shared/users/current-user.service';

interface SelectableAccountAndHoldings extends models.AccountAndHoldings {
    selected: boolean;
}

interface SelectableSelectItem extends SelectItem {
    selected: boolean;
}

interface SecurityHoldings {
    securityId: number;
    holdings: models.SecurityHolding[];
}

@Component({
    selector: 'my-account-analytics',
    templateUrl: './account-analytics.component.html'
})
export class AccountAnalyticsComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private usersProxy: UsersProxy,
        private accountsProxy: AccountsProxy,
        private securitiesProxy: SecuritiesProxy,
        private currentUser: CurrentUserService
    ) {
        super();
    }

    isBusy = false;
    filteredAccounts: SelectableAccountAndHoldings[] = [];
    allAccounts: SelectableAccountAndHoldings[] = [];
    accountPurposes: SelectableSelectItem[] = [];
    benchmarks: models.Security[] = [];
    securityHoldings: { [securityId: number]: models.SecurityHolding[] } = {};

    ngOnInit() {
        this.isBusy = true;

        const accountsObservable = this.loadAccounts();
        const accountPurposesObservable = this.loadAccountPurposes();
        const benchmarkObservable = this.loadBenchmarks();

        forkJoin([accountsObservable, accountPurposesObservable, benchmarkObservable])
            .pipe(
                finalize(() => this.isBusy = false),
                this.takeUntilUnsubscribed()
            )
            .subscribe(
                results => {
                    this.accountPurposes = this.filterUnusedAccountPurposes(results[0], results[1]);
                    this.allAccounts = results[0];
                    this.benchmarks = results[2];
                    this.filterAccounts();
                    this.loadSecurityHoldings();
                });
    }

    getAccountLabel(account: models.AccountAndHoldings) {
        return `${account.institution.name}: ${account.name}`;
    }

    onAccountFilterChanged() {
        this.filterAccounts();
    }

    onAccountPurposeChanged() {
        this.selectAccountsByPurpose();
        this.filterAccounts();
    }

    private loadAccounts() {
        return this.usersProxy.getUserAccountsAndHoldings(this.currentUser.userId)
            .pipe(
                map(
                response => {
                    return response.body.map(a => {
                        const selectable = a as SelectableAccountAndHoldings;
                        selectable.selected = true;
                        return selectable;
                    });
                })
            );
    }

    private loadAccountPurposes() {
        return this.accountsProxy.getAccountPurposes()
            .pipe(
                map(
                response => {
                    return response.body.map(ap => {
                        return {
                            value: ap.accountPurposeId,
                            label: `Purpose: ${ap.name}`,
                            selected: true
                        };
                    });
                })
            );
    }

    private loadBenchmarks() {
        const vtsax = 1;
        return this.securitiesProxy.getSecurity(vtsax)
            .pipe(
                map(
                response => {
                    return [response.body];
                })
            );
    }

    private loadSecurityHoldings() {
        const mutualFund = 1;
        const etf = 2;
        const securityTypesWithHoldings = new Set([mutualFund, etf]);
        const securityIdsToLoad = new Set<number>();

        this.allAccounts.forEach(account => {
            account.holdings
                .filter(holding => securityTypesWithHoldings.has(holding.security.securityType.securityTypeId))
                .filter(holding => !this.securityHoldings[holding.security.securityId])
                .forEach(holding => {
                    securityIdsToLoad.add(holding.security.securityId);
                });
        });

        if(securityIdsToLoad.size > 0) {
            const observables: Observable<SecurityHoldings>[] = [];

            securityIdsToLoad.forEach(securityId => {
                const observable: Observable<SecurityHoldings> = this.securitiesProxy.getHoldings(securityId)
                    .pipe(
                        map(response => {
                            return {
                                securityId: securityId,
                                holdings: response.body
                            } as SecurityHoldings;
                        })
                    );

               observables.push(observable);
            });

            forkJoin(observables)
                .pipe(
                    this.takeUntilUnsubscribed()
                )
                .subscribe(
                    results => {
                        const securityHoldings: { [securityId: number]: models.SecurityHolding[] } = {};

                        results.forEach(result => {
                            if(result.holdings.length > 0) {
                                securityHoldings[result.securityId] = result.holdings;
                            }
                        });

                        this.securityHoldings = securityHoldings;
                    });
        }
    }

    private filterUnusedAccountPurposes(
        accounts: SelectableAccountAndHoldings[],
        accountPurposes: SelectableSelectItem[]) {

        const usedAccountPurposeIds = accounts.map(a => a.accountPurpose.accountPurposeId);
        return accountPurposes.filter(ap => usedAccountPurposeIds.indexOf(ap.value) !== -1);
    }

    private selectAccountsByPurpose() {
        const selectedAccountPurposeIds = this.accountPurposes.filter(ap => ap.selected).map(ap => ap.value);
        this.allAccounts.forEach(a => {
            a.selected = selectedAccountPurposeIds.indexOf(a.accountPurpose.accountPurposeId) !== -1;
        });
    }

    private filterAccounts() {
        this.filteredAccounts = this.allAccounts.filter(a => a.selected);
    }
}
