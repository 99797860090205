<p-table [value]="holdings" [loading]="isBusy" tableStyleClass="p-datatable-responsive-md">
    <ng-template pTemplate="body" let-holding let-rowIndex="rowIndex">
        <tr class="p-widget-header p-rowgroup-header" *ngIf="shouldShowAccountRowGroupHeader(rowIndex)" valign="bottom">
            <td class="left">
                {{holding.account.institution.name}}: {{holding.account.name}}<br />
                Opened {{holding.account.openDate | date:'shortDate'}}
                <span *ngIf="holding.account.closeDate">
                    <br />Closed {{holding.account.closeDate | date:'shortDate'}}
                </span>
            </td>
            <td class="width1 right">Price</td>
            <td class="width3 right">Shares</td>
            <td class="width3 right">Balance</td>
            <td *ngFor="let col of returnColumns" class="return-col right" innerHtml="{{col.label}}"></td>
        </tr>
        <tr>
            <td class="left">
                <span class="p-column-title">Name</span>
                {{holding.security.displayText}}
                <span *ngIf="holding.accountHoldingId > 0" class="icons">
                    <br />
                    <i class="fas fa-chart-line line-chart clickable balance-history icon" pTooltip="Balance History" (click)="openBalanceHistoryDialog(holding)"></i>
                    <i class="fas fa-info-circle clickable security-details icon" pTooltip="Security Details" [routerLink]="['/securities', holding.security.securityId]"></i>
                    <i class="fas fa-percentage allocation icon" [pTooltip]="getAllocationTooltip(holding)" [ngClass]="getAllocationDifferenceCssClass(holding.currentAccountAllocation - holding.targetAllocation, false)"></i>
                    <i *ngIf="holding.notes" class="fas fa-sticky-note notes icon" [pTooltip]="holding.notes"></i>
                    <i *ngIf="holding.security.expenseRatio" class="fas fa-donate expense-ratio icon" [pTooltip]="'Expense Ratio: ' + (holding.security.expenseRatio | percent:'1.2-2')"></i>
                </span>
            </td>
            <td class="right" [pTooltip]="getPriceAsOfTooltip(holding.security)">
                <span class="p-column-title">Price</span>
                {{(shouldShowHoldingPrice(holding) ? holding.securityAlternatePrice.price : holding.security.price) | currency}}
                <span *ngIf="shouldShowSecurityPriceChangePercent(holding)" [ngClass]="getFinancialReturnCssClass(holding.security.priceChangePercent)">
                    <br class="non-responsive-only" />
                    {{holding.security.priceChangePercent | percent:'1.2-2'}}
                </span>
            </td>
            <td class="right">
                <span class="p-column-title">Shares</span>
                {{holding.shares | number:'1.4-4'}}
            </td>
            <td class="right">
                <span class="p-column-title">Balance</span>
                {{holding.balance | currency}}
            </td>
            <td *ngFor="let col of returnColumns" class="right">
                <span class="p-column-title">{{getResponsiveColumnTitle(col)}}</span>
                <span [ngClass]="getReturnCssClass(holding, col.value)">{{getReturn(holding, col.value) | percent:'1.2-2'}}</span>
                <div *ngIf="showInceptionDate(holding, col.value)" class="fs-xsmall">
                    <span [innerHTML]="getInceptionDateDescription(holding)"></span>
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="columnCount">
                {{isBusy ? 'Loading account holdings...' : 'No account holdings.'}}
            </td>
        </tr>
    </ng-template>
</p-table>
